define('aira-mgmt/components/properties/property-display/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init() {
      this._super(...arguments);
    },

    getShortenedKey(key) {
      return key.substring(key.lastIndexOf('.') + 1).toString();
    }
  });
});