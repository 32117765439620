define('aira-mgmt/users/user/subscription/minutes/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    notify: Ember.inject.service(),

    platform: Ember.inject.service(),

    userId: null,

    inProgress: false,

    calculating: false,

    calculated: false,

    purchaseOption: null,

    invoiceCollection: null,

    billingInfo: null,

    purchaseOptions: [],

    isPreviewDisabled: Ember.computed('inProgress', 'purchaseOption', function () {
      // Disable the Preview button if we're loading or if a bundle hasn't been selected.
      return this.inProgress || !this.purchaseOption;
    }),

    actions: {
      purchaseOptionSelected(addonCode) {
        // Default the overrides to the values from the bundle.
        this.get('model.orderOptions.minuteAddOnAdjustments').forEach(bundle => {
          if (bundle.code === addonCode) {
            this.set('overrideBundleQuantity', bundle.minutes);
            this.set('overridePrice', bundle.price / 100);
          }
        });

        this.set('purchaseOption', addonCode);
        this.set('calculated', false);
        this.set('calculating', true);
      },

      overrideChanged() {
        // If an override changed, require another preview.
        this.set('calculated', false);
      },

      preview() {
        this.set('inProgress', true);

        this.get('platform').doPost('care/order/' + this.get('userId') + '/addon/preview', {
          'addOnCode': this.get('purchaseOption'),
          'overrideBundleQuantity': this.get('overrideBundleQuantity'),
          'overridePrice': this.get('overridePrice')
        }).then(response => {
          this.set('calculated', true);

          this.set('invoiceCollection', response.invoiceCollection);

          this.set('planTotal', response.addOn.price);
          this.set('accountBalance', response.accountBalance.balanceInCents['unitAmount' + response.addOn.currency]);

          let sub = response.invoiceCollection.chargeInvoice == null ? 0 : response.invoiceCollection.chargeInvoice.totalInCents;
          let newPlanAdjustment = response.addOn.price - sub;

          let otherAdjustment = response.invoiceCollection.creditInvoices.reduce((acc, val) => {
            return acc + val.totalInCents;
          }, 0);

          this.set('proratedAdjustments', otherAdjustment - newPlanAdjustment);

          let totalCharge = response.invoiceCollection.creditInvoices.reduce((acc, val) => {
            return acc + val.balanceInCents;
          }, response.invoiceCollection.chargeInvoice.balanceInCents);

          this.set('totalCharge', Math.max(totalCharge, 0));

          this.set('billingInfo', response.billingInfo);
        }, error => {
          this.send('error', error);
        }).finally(() => {
          this.set('inProgress', false);
        });
      }
    }
  });
});