define('aira-mgmt/components/accessible-multi-select/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    // Properties
    isExpanded: false,
    activeDescendantId: null,
    selectedItems: null,
    activeIndex: -1,

    // Computed Properties
    optionsWithPosition: Ember.computed('options.[]', function () {
      var options = this.options || [];
      return options.map(function (option, index) {
        return {
          original: option,
          text: option.text,
          position: index + 1,
          total: options.length
        };
      });
    }),

    ariaLabel: Ember.computed('selectedItems.[]', function () {
      var selectedItems = this.selectedItems || [];
      if (selectedItems.length) {
        var selectedTexts = selectedItems.mapBy('text').join(', ');
        return 'Selected languages: ' + selectedTexts;
      }
      return 'No languages selected';
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('selectedItems', Ember.A(this.selected || []));
      this._handleOutsideClick = Ember.run.bind(this, this.handleOutsideClick);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      document.addEventListener('click', this._handleOutsideClick);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      document.removeEventListener('click', this._handleOutsideClick);
    },
    handleOutsideClick: function handleOutsideClick(event) {
      if (this.isExpanded) {
        var componentElement = document.querySelector('.accessible-multi-select');
        if (!componentElement.contains(event.target)) {
          this.set('isExpanded', false);
          this.set('activeIndex', -1);
        }
      }
    },
    focusOption: function focusOption(index) {
      var option = document.querySelector('#option-' + index);
      if (option) {
        option.focus();
      }
    },


    actions: {
      toggleDropdown: function toggleDropdown() {
        this.toggleProperty('isExpanded');
        if (this.isExpanded) {
          this.set('activeIndex', 0);
          this.focusOption(0);
        }
      },
      selectOption: function selectOption(wrappedOption, event) {
        var option = wrappedOption.original || wrappedOption;
        var selectedItems = this.selectedItems;
        if (event) {
          event.preventDefault();
          event.stopPropagation();
        }

        if (selectedItems.includes(option)) {
          selectedItems.removeObject(option);
        } else {
          selectedItems.pushObject(option);
        }

        if (this.onchange) {
          this.onchange(selectedItems);
        }
      },
      handleKeyDown: function handleKeyDown(event) {
        var options = this.optionsWithPosition;
        var activeIndex = this.activeIndex;

        switch (event.key) {
          case 'Escape':
            this.set('isExpanded', false);
            this.set('activeIndex', -1);
            document.querySelector('.multi-select-control').focus();
            break;

          case 'Enter':
            if (!this.isExpanded) {
              this.set('isExpanded', true);
              this.focusOption(0);
            } else if (activeIndex >= 0) {
              this.send('selectOption', options[activeIndex]);
            }
            break;

          case ' ':
            if (!this.isExpanded) {
              this.set('isExpanded', true);
            } else if (activeIndex >= 0) {
              this.send('selectOption', options[activeIndex]);
            }
            break;

          case 'ArrowDown':
            event.preventDefault();
            if (!this.isExpanded) {
              this.set('isExpanded', true);
              this.set('activeIndex', 0);
            } else if (activeIndex < options.length - 1) {
              var newIndex = activeIndex + 1;
              this.set('activeIndex', newIndex);
              this.focusOption(newIndex);
            }
            break;

          case 'ArrowUp':
            event.preventDefault();
            if (!this.isExpanded) {
              this.set('isExpanded', true);
              this.set('activeIndex', 0);
              this.focusOption(0);
            } else if (activeIndex > 0) {
              var _newIndex = activeIndex - 1;
              this.set('activeIndex', _newIndex);
              this.focusOption(_newIndex);
            } else if (activeIndex === 0) {
              this.set('isExpanded', false);
              this.set('activeIndex', -1);
              document.querySelector('.multi-select-control').focus();
            }
            break;

          case 'Home':
            if (this.isExpanded && options.length) {
              this.set('activeIndex', 0);
            }
            break;

          case 'End':
            if (this.isExpanded && options.length) {
              this.set('activeIndex', options.length - 1);
            }
            break;

          case 'Tab':
            this.set('isExpanded', false);
            this.set('activeIndex', -1);
            break;
        }
      }
    }
  });
});