define('aira-mgmt/users/user/service/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['pg'],
    notify: Ember.inject.service(),
    platform: Ember.inject.service(),
    router: Ember.inject.service(),

    /**
     * This is set either by:
     * 1) the #link-to in the "user" template
     * 2) Directly from the URL
     * 3) The setup method in the route
     */
    pg: null,

    timezone: null,

    disableBack: Ember.computed(function () {
      return parseInt(this.get('pg')) <= 0;
    }).property('pg'),

    disableNext: Ember.computed(function () {
      return this.get('model.service.response.hasMore') === false;
    }).property('pg'),

    actions: {
      previousPage() {
        this.decrementProperty('pg');
      },

      nextPage() {
        this.incrementProperty('pg');
      },
      editShareSession(service) {
        for (const s of this.model.service.payload) {
          if (s.id === service.id) {
            Ember.set(s, 'isEditShareSession', true);
          } else {
            Ember.set(s, 'isEditShareSession', false);
          }
        }
      },
      cancelShareSession(service) {
        for (const s of this.model.service.payload) {
          if (s.id === service.id) {
            Ember.set(s, 'shareSession', !s.shareSession);
            Ember.set(s, 'isEditShareSession', false);
          }
        }
      },
      saveChangeShareSession(service) {
        this.platform.doPut(`mgmt/service-request/${service.id}/build-ai/allow-sharing`, {
          value: !service.buildAi.explorerAllowSharing
        }).then(() => {
          this.notify.success(`Updated share for service with ID ${service.id}.`);
          for (const s of this.model.service.payload) {
            if (s.id === service.id) {
              Ember.set(s, 'isEditShareSession', false);
            }
          }
          this.send('forceRefreshUserService');
        }, ({ errorMessage }) => {
          this.notify.error(errorMessage);
        });
      }
    }
  });
});