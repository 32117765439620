define("aira-mgmt/accounts/account/products/product/edit/controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    id: null,
    editClass: null,
    editClasses: [{ text: "product", id: "product" }, { text: "promotion", id: "promotion" }],
    editKey: null,
    editEnabled: null,
    editTermsAndConditions: null,
    editTermsAndConditionsUrl: null,
    editAgentMessage: null,
    editEffectiveFrom: null,
    editEffectiveTo: null,
    editDurationAllowed: null,
    editDurationPerCall: null,
    editCallPeriodLength: null,
    editCallsPerPeriod: null,
    editEnforcedOnDuration: null,
    editRequireAgentApproval: null,
    editVisible: null,
    editSticky: null,
    editEntireCall: null,
    editAvailableToGuests: null,
    editEnforcedOnExplorers: null,
    editLimitDurationPerCall: null,
    editLimitDurationPerCallIsDisabled: null,
    editCallLimitCheckbox: null,
    editLimitCallFrequency: null,
    editLimitCallFrequencyIsDisabled: null,
    editCallFrequencyCheckbox: null,
    editErrorMessage: null,
    editingProductPromotion: null,
    attributesLocalized: null,

    actions: {

      editProductPromotion(selected) {
        this.set('editClass', selected);
        console.log('SELECTION:', this.get('editClass'));
      },

      editCallLimit(ele) {
        let enabled = document.getElementById("editDurationPerCallEnabled");
        if (ele.target.checked) {
          enabled.disabled = false;
        } else {
          enabled.disabled = true;
        }
        this.editCallLimitCheckbox = ele.target.checked;
      },

      editFrequencyLimit(ele) {
        let callsperPeriod = document.getElementById("editCallsPerPeriodEnabled");
        let callPeriodLength = document.getElementById("editCallPeriodLengthEnabled");
        let enforcedDuration = document.getElementById("editEnforcedDurationEnabled");
        let enforceOnExplorers = document.getElementById("editEnforcedOnExplorers");
        if (ele.target.checked) {
          callsperPeriod.disabled = false;
          callPeriodLength.disabled = false;
          enforcedDuration.disabled = false;
          enforceOnExplorers.disabled = false;
        } else {
          callsperPeriod.disabled = true;
          callPeriodLength.disabled = true;
          enforcedDuration.disabled = true;
          enforceOnExplorers.disabled = true;
        }
        this.editCallFrequencyCheckbox = ele.target.checked;
      }
    }
  });
});