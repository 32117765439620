define('aira-mgmt/users/user/chats/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['pg'],

    pg: 0,

    timezone: null,

    disableBack: Ember.computed('pg', function () {
      return parseInt(this.pg) === 0;
    }),

    disableNext: Ember.computed('model.service.response.hasMore', function () {
      return this.model.chats.response.hasMore === false;
    }),

    actions: {
      previousPage() {
        this.decrementProperty('pg');
      },

      nextPage() {
        this.incrementProperty('pg');
      }
    }
  });
});