define('aira-mgmt/onboard/index/route', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({

		actions: {
			next(user, userProps) {
				user.setProperties(userProps);
				user.save().then(() => this.transitionTo('onboard.google', user), error => this.send('error', error));
			}
		}
	});
});