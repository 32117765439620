define('aira-mgmt/helpers/is-multi', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isMulti = isMulti;
  function isMulti(type) {
    return type.toString().startsWith('MULTI');
  }

  exports.default = Ember.Helper.helper(isMulti);
});