define("aira-mgmt/helpers/get-epoch-date", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getEpochDate = getEpochDate;
  function getEpochDate(epoch) {
    return new Date(Number(epoch)).toUTCString();
  }

  exports.default = Ember.Helper.helper(getEpochDate);
});