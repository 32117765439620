define('aira-mgmt/helpers/build-ia-status', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.buildIaStatus = buildIaStatus;
    function buildIaStatus(params /*, hash*/) {
        const onlyValue = params[1];
        if (params[0] == null) {
            if (onlyValue) {
                return '';
            }
            return 'Can only be enabled by the explorer on the call history page';
        }
        let print = '';
        let status = params[0];

        print = status.toLowerCase().split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        if (onlyValue) {
            return print;
        }
        return "Can not be changed reason: " + print;
    }

    exports.default = Ember.Helper.helper(buildIaStatus);
});