define('aira-mgmt/accounts/account/sites/site/edit/route', ['exports', 'aira-mgmt/utils/validate-dates', 'aira-mgmt/helpers/change-PST-to-ISO', 'aira-mgmt/helpers/change-ISO-to-PST', 'aira-mgmt/accounts/account/route'], function (exports, _validateDates, _changePSTToISO, _changeISOToPST, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Route.extend({

    notify: Ember.inject.service(),

    platform: Ember.inject.service(),

    model: function model() {
      return this.modelFor('accounts.account.sites.site').site;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.set('controller.fence', null);
      if (model.fence) {
        this.set('controller.fenceData', JSON.stringify(model.fence, null, "\t"));
        var url = 'https://geojson.io/#data=data:application/json,' + encodeURIComponent(JSON.stringify(model.fence));
        this.set('controller.geojsonUrl', url);
      }
      if (model.durationPerCall === -1) {
        this.set('controller.siteDurationPerCall', null);
      } else {
        this.set('controller.siteDurationPerCallCheckbox', true);
        this.set('controller.siteDurationPerCall', model.durationPerCall);
      }
      this.set('controller.attributesLocalized', (0, _route.addMissingLangLocalizations)(model.attributesLocalized));
      this.set('controller.model.effectiveFrom', (0, _changeISOToPST.changeISOtoPST)([model.effectiveFrom]));
      this.set('controller.model.effectiveTo', (0, _changeISOToPST.changeISOtoPST)([model.effectiveTo]));
    },


    actions: {
      cancelSite: function cancelSite(accountId) {
        this.transitionTo('accounts.account.sites', accountId);
      },
      updateSite: function updateSite() {
        var _this = this;

        var siteId = this.modelFor('accounts.account.sites.site').site.id;
        var accountId = this.modelFor('accounts.account').account.accountId;

        // check site information
        var dataIsGood = true;
        var errorMessage = [];
        if (this.get('controller.model.agentMessage') === '' || this.get('controller.model.agentMessage') == null) {
          dataIsGood = false;
          errorMessage.push('Missing agent message');
        }

        var effectiveFromError = (0, _validateDates.validateDate)(this.get('controller.model.effectiveFrom'), 'effective from', true);
        if (effectiveFromError) {
          dataIsGood = false;
          errorMessage.push(effectiveFromError);
        }

        var effectiveToValue = this.get('controller.model.effectiveTo');
        if (effectiveToValue !== '' && effectiveToValue != null) {
          var effectiveToError = (0, _validateDates.validateDate)(effectiveToValue, 'effective to', false);
          if (effectiveToError) {
            dataIsGood = false;
            errorMessage.push(effectiveToError);
          }
        }

        if (this.get('controller.model.siteAddress.address1') === '' || this.get('controller.model.siteAddress.address1') === undefined) {
          dataIsGood = false;
          errorMessage.push('Missing address line 1');
        }
        if (this.get('controller.model.siteAddress.city') === '' || this.get('controller.model.siteAddress.city') === undefined) {
          dataIsGood = false;
          errorMessage.push('Missing city');
        }
        if (this.get('controller.model.siteAddress.country') === '' || this.get('controller.model.siteAddress.country') === undefined) {
          dataIsGood = false;
          errorMessage.push('Missing country');
        }
        if (this.get('controller.model.fence') === '' || this.get('controller.model.fence') === undefined) {
          dataIsGood = false;
          errorMessage.push('Missing fence');
        }
        if (this.get('controller.siteDurationPerCallCheckbox')) {
          if (this.get('controller.siteDurationPerCall') === '' || this.get('controller.siteDurationPerCall') === undefined) {
            dataIsGood = false;
            errorMessage.push('Missing enforced duration per call');
          } else {
            var value = parseInt(this.get('controller.siteDurationPerCall'));
            if (value <= 0 || isNaN(value)) {
              dataIsGood = false;
              errorMessage.push('Duration per call value must be a valid number greater than 0');
            }
          }
        }

        var validationArrayRsp = (0, _route.validateAttributesLocalized)(this.get('controller.attributesLocalized'));
        if (!validationArrayRsp[0]) {
          dataIsGood = false;
          errorMessage.push.apply(errorMessage, _toConsumableArray(validationArrayRsp[1]));
        }

        if (dataIsGood) {
          console.log('data is validated and good, invoking HTTP PUT');
          this.get('platform').doPut(['access', 'site', siteId].join('/'), {
            account: {
              id: accountId
            },
            accountId: accountId,
            id: siteId,
            class: 'site',
            type: "public",
            termsAndConditions: this.get('controller.model.termsAndConditions') ? this.get('controller.model.termsAndConditions') : null,
            agentMessage: this.get('controller.model.agentMessage'),
            effectiveFrom: this.get('controller.model.effectiveFrom') ? (0, _changePSTToISO.changePSTtoISO)([this.get('controller.model.effectiveFrom')]) : null,
            effectiveTo: this.get('controller.model.effectiveTo') ? (0, _changePSTToISO.changePSTtoISO)([this.get('controller.model.effectiveTo')]) : null,
            partnerReferenceId: this.get('controller.model.partnerReferenceId') ? this.get('controller.model.partnerReferenceId') : null,
            attributesLocalized: (0, _route.removeNotSetLocalizations)(this.get('controller.attributesLocalized')),
            siteAddress: {
              accountId: accountId,
              address1: this.get('controller.model.siteAddress.address1'),
              address2: this.get('controller.model.siteAddress.address2') ? this.get('controller.model.siteAddress.address2') : null,
              city: this.get('controller.model.siteAddress.city'),
              state: this.get('controller.model.siteAddress.state'),
              country: this.get('controller.model.siteAddress.country'),
              zip: this.get('controller.model.siteAddress.zip'),
              addressType: 'site'
            },
            fence: JSON.parse(this.get('controller.fenceData')),
            durationPerCall: this.get('controller.siteDurationPerCallCheckbox') ? parseInt(this.get('controller.siteDurationPerCall')) : -1,
            enabled: this.get('controller.model.enabled'),
            requireAgentApproval: this.get('controller.model.requireAgentApproval'),
            searchable: this.get('controller.model.searchable'),
            displayable: this.get('controller.model.displayable'),
            gpsActivated: this.get('controller.model.gpsActivated'),
            bufferAroundFence: this.get('controller.model.bufferAroundFence'),
            serviceDomain: this.get('controller.model.serviceDomain')
          }).then(function () {
            console.log('HTTP PUT was successful');
            _this.get('notify').success('Site updated!');
            _this.transitionTo('accounts.account.sites', accountId);
          }, function (error) {
            console.error('HTTP PUT errored out');
            _this.send('error', error.errorMessage);
          });
          this.set('controller.errorMessage', null);
        } else {
          this.set('controller.errorMessage', errorMessage);
        }
      }
    }
  });
});