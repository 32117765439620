define("aira-mgmt/routes/application", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    state: Ember.inject.service(),

    session: Ember.inject.service(),

    notify: Ember.inject.service(),

    moment: Ember.inject.service(),

    beforeModel() {
      this.get("moment").setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    },

    setupController(controller, model) {
      this._super(controller, model);
      // Provide session service to generated controller, avoids making our own.
      controller.set("session", this.get("session"));
      controller.set("state", this.get("state"));
    },

    actions: {
      account() {
        alert("Coming Soon.");
      },

      logout() {
        this.get("session").invalidate().then(() => this.transitionTo("login"));
      },

      /**
      * Handle all errors.
      * @param error One of: {errors:string}, {message:string}, {errorCode:string,errorMessage:string}, string
       **/
      error(error) {
        let message = "An unknown error has occurred.";

        if (error && error.errors) {
          message = error.errors[0].detail;
          let code = error.errors[0].status;
          if (code === "SEC-001") {
            this.get("session").invalidate().then(() => this.transitionTo("login"));
          }
        } else if (error && error.message) {
          message = error.message;
        } else if (error && error.errorCode && error.errorMessage) {
          message = [error.errorCode, error.errorMessage].join(": ");
        } else if (error) {
          message = error;
        }

        this.get("notify").error(message);
      }
    }
  });
});