define("aira-mgmt/helpers/either", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.either = either;
  function either([leftSide, rightSide] /*, hash*/) {
    return leftSide || rightSide;
  }

  exports.default = Ember.Helper.helper(either);
});