define('aira-mgmt/accounts/account/sites/site/edit/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    geojsonUrl: null,

    fence: null,

    fenceData: null,

    actions: {
      updateServiceDomain: function updateServiceDomain(type, event) {
        var serviceDomain = this.get('model.serviceDomain');
        if (!serviceDomain || !Array.isArray(serviceDomain)) {
          return;
        }

        var item = serviceDomain.find(function (item) {
          return item.type === type;
        });
        if (item) {
          // Get the checked state from the event
          var checked = event.target.checked;
          // Update the value as a boolean
          item.value = checked;
          // Trigger property change notification
          this.notifyPropertyChange('model.serviceDomain');
        }
      }
    }
  });
});