define('aira-mgmt/helpers/duration-used', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.durationUsed = durationUsed;
  function durationUsed([access]) {
    if (access.userType !== 'business') {
      return '';
    }
    return (0, _emberInflector.pluralize)(Math.ceil(access.durationUsed / 60), 'minute');
  }

  exports.default = Ember.Helper.helper(durationUsed);
});