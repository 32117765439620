define('aira-mgmt/components/data-input', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.TextField.extend({

		autofocus: false,

		mask: null,

		didInsertElement() {
			if (this.get('autofocus') === true) {
				this.$().focus();
			}

			if (this.get('mask') !== null) {
				let mask = this.get('mask');
				this.$().mask(mask, {
					autoclear: false,
					placeholder: ' '
				});
			}
		}
	});
});