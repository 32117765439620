define('aira-mgmt/helpers/duration', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.duration = duration;
  function duration([minutes, seconds]) {
    if (minutes === 0) {
      return `${seconds} seconds`;
    }
    seconds = seconds - minutes * 60;
    return `${minutes} minutes ${seconds} seconds`;
  }

  exports.default = Ember.Helper.helper(duration);
});