define('aira-mgmt/users/user/accounts/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),

    notify: Ember.inject.service(),

    beforeModel() {},

    actions: {
      selectAccount(accountId) {
        this.transitionTo('accounts.account', accountId);
      }
    }
  });
});