define('aira-mgmt/users/user/subscription/route', ['exports', 'aira-mgmt/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    notify: Ember.inject.service(),

    platform: Ember.inject.service(),

    queryParams: {
      // HACK: Because I'm an Ember noob, I don't know of a better way to forcibly refresh the model on a transition.
      refreshModel: {
        refreshModel: true
      }
    },

    model() {
      let recurlyUrlPrefix = _environment.default.APP.env === 'prod' ? "https://aira.recurly.com/accounts/" : "https://aira-" + _environment.default.APP.env + ".recurly.com/accounts/";

      return Ember.RSVP.hash({
        // API Call #1 - Subscription details
        billing: new Ember.RSVP.Promise((resolve, reject) => {
          this.get('platform').doGet('subscription?userId=' + this.modelFor('users.user').id).then(subscription => {
            resolve(Ember.Object.create({
              noAccount: false,
              noSubscription: false,
              subscription: subscription.subscription,
              name: subscription.name,
              planCode: subscription.planCode,
              showActivateButton: subscription.subscription == null || subscription.subscription.state !== 'active',
              primaryUserId: subscription.primaryUserId,
              accountType: subscription.accountType,
              recurlyUrlPrefix: recurlyUrlPrefix
            }));
          }, ({ errorCode, errorMessage }) => {
            if (errorCode === 'KN-ACC-002') {
              resolve(Ember.Object.create({
                noAccount: true,
                isPrimary: false,
                isSecondary: false,
                isBusiness: false,
                noSubscription: true,
                recurlyUrlPrefix: recurlyUrlPrefix,
                accountCode: this.modelFor('users.user').accountCode
              }));
            } else if (errorCode === 'BIZ-SUB-002') {
              console.log(this.modelFor('users.user'));
              resolve(Ember.Object.create({
                noAccount: false,
                isPrimary: this.modelFor('users.user').accounts.find(acc => acc.userType === 'primary'),
                isSecondary: this.modelFor('users.user').accounts.find(acc => acc.userType === 'secondary'),
                isBusiness: this.modelFor('users.user').accounts.find(acc => acc.userType === 'business'),
                noSubscription: true,
                recurlyUrlPrefix: recurlyUrlPrefix,
                accountCode: this.modelFor('users.user').accountCode
              }));
            } else {
              reject({ errorCode, errorMessage });
            }
          });
        }),
        // API Call #2 - Usage details
        usage: new Ember.RSVP.Promise((resolve, reject) => {
          this.get('platform').doGet('smartapp/usage/' + this.modelFor('users.user').id).then(usage => {
            resolve(Ember.Object.create(usage));
          }, ({ errorCode, errorMessage }) => {
            if (errorCode === 'KN-ACC-002') {
              resolve(Ember.Object.create({
                noAccount: true,
                noSubscription: true
              }));
            } else if (errorCode === 'BIZ-SUB-002') {
              resolve(Ember.Object.create({
                noSubscription: true
              }));
            } else {
              reject({ errorCode, errorMessage });
            }
          });
        }),
        // API Call #3 - Orders options
        orderOptions: new Ember.RSVP.Promise((resolve, reject) => {
          this.get('platform').doGet('care/order/options?userId=' + this.modelFor('users.user').id).then(orderOptions => {
            resolve(Ember.Object.create(orderOptions));
          }, ({ errorCode, errorMessage }) => {
            if (errorCode === 'KN-ACC-002') {
              resolve(Ember.Object.create({
                noAccount: true,
                noSubscription: true
              }));
            } else if (errorCode === 'BIZ-SUB-002') {
              reject({ errorCode, errorMessage });
            } else if (errorCode === 'KN-ACC-009') {
              // Create a dummy response - routes handle this
              resolve(Ember.Object.create({}));
            } else {
              reject({ errorCode, errorMessage });
            }
          });
        })
      });
    },

    setupController(controller, model) {
      this._super(controller, model);
      controller.set('inProgress', false);
      controller.set('ssp', _environment.default.APP.ssp);
      controller.set('phoneNumber', this.modelFor('users.user').phoneNumber);
    },

    actions: {
      activate() {
        this.controller.set('inProgress', true);
        this.get('platform').doPost('activate', {
          userId: this.modelFor('users.user').id
        }).then(() => {
          this.get('notify').success('Subscription activated!');
          this.refresh();
        }, error => {
          this.controller.set('inProgress', false);
          this.send('error', error);
        });
      }
    }
  });
});