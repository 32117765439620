define('aira-mgmt/users/user/contacts/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),
    contacts: Ember.computed.alias('state.users.user.contacts'),

    notify: Ember.inject.service(),

    model() {
      this.set('contacts.selectedContactId', -1);

      return this.get('store').createRecord('contact', {
        user: this.modelFor('users.user'),
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        relationship: null,
        type: 'Emergency'
      });
    },

    deactivate() {
      // Not done in cancel incase user navigates away by another means
      this.get('currentModel').rollbackAttributes();
    },

    actions: {
      willTransition: function (transition) {
        if (this.get('state.isDirty') && !confirm('Are you sure? You have unsaved changes.')) {
          transition.abort();
        }
      },

      save(contact, contactProps) {
        contact.setProperties(contactProps);
        return contact.save().then(() => {
          this.set('state.isDirty', false);
          this.get('notify').success('Contact was added.');
          this.transitionTo('users.user.contacts');
        }, error => {
          this.send('error', error);
        });
      },

      cancel() {
        this.transitionTo('users.user.contacts');
      }
    }
  });
});