define('aira-mgmt/users/user/chats-v2/route', ['exports', 'aira-mgmt/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      pg: {
        refreshModel: true
      }
    },

    firebase: Ember.inject.service(),

    model(params) {
      const userId = this.modelFor('users.user').id;
      return Ember.RSVP.hash({
        rooms: new Ember.RSVP.Promise((resolve, reject) => {
          this.firebase.getIdToken().then(idToken => {
            $.ajax({
              url: `${_environment.default.APP.aira_api_base_url}/chat/user/${userId}/rooms?page=${parseInt(params.pg) > 1 ? params.pg : 1}`,
              method: 'GET',
              contentType: 'application/json',
              headers: {
                'Authorization': `Bearer ${idToken}`
              },
              success(data) {
                resolve(data);
              },
              error(jqXHR) {
                reject(jqXHR);
              }
            });
          }).catch(error => reject(error));
        })
      });
    },

    setupController(controller, model) {
      this._super(controller, model);
      controller.set('timezone', new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]);
    },

    actions: {
      openChatReport(room) {
        window.open(`${_environment.default.APP.ssp}/internal/chatReport?chatId=${room.roomId}`, '_blank');
      }
    }
  });
});