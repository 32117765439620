define('aira-mgmt/authenticators/firebase', ['exports', 'ember-simple-auth/authenticators/base', 'aira-mgmt/config/environment'], function (exports, _base, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    firebase: Ember.inject.service(),
    notify: Ember.inject.service(),

    /**
     * Restores the session from a session data object. This method is invoked by
     * the session either on application startup if session data is restored from
     * the session store or when properties in the store change due to external
     * events (e.g. in another tab) and the new session data needs to be validated
     * for whether it constitutes an authenticated session.
     *
     * This method returns a promise. A resolving promise results in the session
     * becoming or remaining authenticated. Any data the promise resolves with
     * will be saved in and accessible via the session service's
     * `data.authenticated` property. A rejecting promise indicates that `data`
     * does not constitute a valid session and will result in the session being
     * invalidated or remaining unauthenticated.
     *
     * See {@link https://ember-simple-auth.com/api/BaseAuthenticator.html#.restore}
     *
     * @param data The data to restore the session from.
     * @returns {Promise} A promise that when it resolves results in the session
     *          becoming or remaining authenticated.
     */
    restore(data) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this._validateToken(data.id, data.token).then(loginResponse => this.get('firebase').loginWithCustomToken(loginResponse.firebaseCustomToken)).then(() => resolve(data)).catch(error => reject(error));
      });
    },

    /**
     * Authenticates the session.
     *
     * This is called by the `login` route. If the Firebase Authentication sign-in
     * redirect was successful, this will use the Firebase ID token to log in and
     * get an Aira token.
     *
     * See {@link https://ember-simple-auth.com/api/BaseAuthenticator.html#.authenticate}
     *
     * @returns {Promise} A promise that when it resolves results in the session
     *          becoming authenticated.
     */
    authenticate() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.get('firebase').handleAuthentication().then(userCredential => {
          if (userCredential == null) {
            return reject();
          }
          return userCredential.user.getIdToken().then(idToken => this._login(userCredential.user.email, idToken)).then(loginResponse => this.get('firebase').loginWithCustomToken(loginResponse.firebaseCustomToken).then(() => loginResponse)).then(loginResponse => resolve({
            id: loginResponse.userid,
            login: loginResponse.login,
            token: loginResponse.token
          }));
        }).catch(error => {
          this.get('notify').error(`Login failed: ${error}`);
          return reject();
        });
      });
    },

    /**
     * This method is invoked as a callback when the session is invalidated.
     *
     * See {@link https://ember-simple-auth.com/api/BaseAuthenticator.html#.invalidate}
     *
     * @returns {Promise} A promise that when it resolves results in the session being invalidated.
     */
    invalidate() {
      return new Ember.RSVP.Promise(resolve => {
        this.get('firebase').logout().then(() => resolve());
      });
    },

    /**
     * Validates a token by calling the Platform
     * {@link https://github.com/aira/platform/blob/753ee3e0e9eb1487d0325290f281b115f73d8abc/core/server/AiraPlatform/src/main/java/io/aira/rest/LoginRestController.java#L70|`/api/user/login/validate-token`}
     * endpoint.
     *
     * @param userId The user ID.
     * @param token The token to be validated.
     * @returns {Promise} A promise that when it resolves results in the
     *          {@link https://github.com/aira/platform/blob/753ee3e0e9eb1487d0325290f281b115f73d8abc/core/server/AiraPlatformManager/src/main/java/io/aira/dto/login/LoginResponse.java|`LoginResponse`}
     *          from Platform.
     * @private
     */
    _validateToken(userId, token) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        $.ajax(`${_environment.default.APP.host}/api/user/login/validate-token`, {
          headers: {
            'x-aira-token': token,
            'x-api-key': _environment.default.APP.X_API_Key,
            'x-client-id': _environment.default.APP.X_Client_Id
          },
          method: 'GET',
          success(data) {
            if (data.userId === userId) {
              resolve(data);
            } else {
              // We have somebody else's token.
              reject();
            }
          },
          error(jqXHR) {
            reject(jqXHR);
          }
        });
      });
    },

    /**
     * Logs in the user by calling the Platform
     * {@link https://github.com/aira/platform/blob/753ee3e0e9eb1487d0325290f281b115f73d8abc/core/server/AiraPlatform/src/main/java/io/aira/rest/LoginRestController.java#L55|`/api/user/login`}
     * endpoint.
     *
     * @param login The email address.
     * @param password The Firebase ID token.
     * @returns {Promise} A promise that when it resolves results in the
     *          {@link https://github.com/aira/platform/blob/753ee3e0e9eb1487d0325290f281b115f73d8abc/core/server/AiraPlatformManager/src/main/java/io/aira/dto/login/LoginResponse.java|`LoginResponse`}
     *          from Platform.
     * @private
     */
    _login(login, password) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        $.ajax(`${_environment.default.APP.host}/api/user/login`, {
          contentType: 'application/json',
          headers: {
            'x-api-key': _environment.default.APP.X_API_Key,
            'x-client-id': _environment.default.APP.X_Client_Id
          },
          method: 'POST',
          data: JSON.stringify({
            authProvider: 'FIREBASE',
            login: login,
            loginfrom: 'MGMT DASHBOARD',
            password: password
          }),
          success(data) {
            if (!data.rolePermissions.find(rp => rp.roleName === 'ADMIN')) {
              reject('Insufficient permissions.');
            } else {
              resolve(data);
            }
          },
          error(jqXHR) {
            reject(jqXHR);
          }
        });
      });
    }
  });
});