define('aira-mgmt/users/user/subscription/upgrade/controller', ['exports', 'accounting/format-money'], function (exports, _formatMoney) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    notify: Ember.inject.service(),

    platform: Ember.inject.service(),

    userId: null,

    newPlan: null,

    invoiceCollection: null,

    billingInfo: null,

    inProgress: false,

    calculating: false,

    calculated: false,

    actions: {
      newPlanSelected(newPlan) {
        this.set('newPlan', newPlan);

        this.set('calculated', false);
        this.set('calculating', true);

        this.get('platform').doPost('care/order/' + this.get('userId') + '/primary/preview', {
          "orderlines": [{
            "productCode": newPlan,
            "quantity": 1,
            "productType": "primary"
          }]
        }).then(response => {
          this.set('calculated', true);
          this.set('calculating', false);

          this.set('invoiceCollection', response.invoiceCollection);

          this.set('planTotal', response.plan.price);
          this.set('accountBalance', response.accountBalance.balanceInCents['unitAmount' + response.plan.currency]);

          let sub = response.invoiceCollection.chargeInvoice == null ? 0 : response.invoiceCollection.chargeInvoice.totalInCents;
          let newPlanAdjustment = response.plan.price - sub;

          let otherAdjustment = response.invoiceCollection.creditInvoices.reduce((acc, val) => {
            console.log(acc);
            return acc + val.totalInCents;
          }, 0);

          console.log(newPlanAdjustment);
          console.log(otherAdjustment);
          this.set('proratedAdjustments', otherAdjustment - newPlanAdjustment);

          let totalCharge = response.invoiceCollection.creditInvoices.reduce((acc, val) => {
            return acc + val.balanceInCents;
          }, response.invoiceCollection.chargeInvoice.balanceInCents);

          this.set('totalCharge', Math.max(totalCharge, 0));

          this.set('billingInfo', response.billingInfo);
        }, error => {
          this.set('calculated', false);
          this.set('calculating', false);

          this.send('error', error);
        });
      }
    },

    newPlanOptions: Ember.computed(function () {
      return this.get('model.orderOptions.primaryPlans').map(plan => {
        console.log(plan);
        let dollars = Math.abs(plan.price) >= 100 ? plan.price / 100 : plan.price;
        let price = (0, _formatMoney.default)(dollars, plan.currency, 2, ',', '.', '%v %s');
        let text = ' - ' + price + '/mo - ' + plan.minutes;
        // if(!plan.supportsHardware) { // todo broken on platform AC-48
        //   text += ' (No Glasses)';
        // }
        return { id: plan.planCode, text: plan.planName + text };
      });
    })
  });
});