define('aira-mgmt/users/user/chats/route', ['exports', 'aira-mgmt/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      pg: {
        refreshModel: true
      }
    },

    platform: Ember.inject.service(),

    model(params) {
      const userId = this.modelFor('users.user').id;
      return Ember.RSVP.hash({
        chats: new Promise((resolve, reject) => {
          this.platform.doGet(`chat?userId=${userId}&page=${params.pg}`).then(chats => {
            resolve(Ember.Object.create(chats));
          }, ({ errorCode, errorMessage }) => {
            reject({ errorCode, errorMessage });
          });
        })
      });
    },

    setupController(controller, model) {
      this._super(controller, model);
      controller.set('timezone', new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]);
    },

    actions: {
      openChatReport(chat) {
        window.open(`${_environment.default.APP.ssp}/internal/chatReport?chatId=${chat.id}`, '_blank');
      }
    }
  });
});