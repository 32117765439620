define('aira-mgmt/services/notify', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({

		success(message) {
			console.info(message);
			Ember.$.jGrowl(message, {
				header: 'Success!',
				speed: 200,
				closeTemplate: '',
				theme: 'bg-success',
				position: 'bottom-right'
			});
		},

		error(message) {
			console.error(message);
			Ember.$.jGrowl(message, {
				header: 'Error!',
				speed: 200,
				life: 30000,
				theme: 'bg-danger',
				position: 'bottom-right'
			});
		}
	});
});