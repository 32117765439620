define('aira-mgmt/apps/app/controller', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		notify: Ember.inject.service(),

		actionOptions: [{ id: 'upsert', text: 'upsert' }, { id: 'forceUpsert', text: 'forceUpsert' }, { id: 'delete', text: 'delete' }],

		changeset: null,

		actions: {
			selectAction(action) {
				this.changeset.set('action', action);
			},

			cancel() {
				this.changeset.rollback();
				this.transitionToRoute('apps');
			},

			save() {
				return this.changeset.save().then(() => {
					this.get('notify').success('App Version was updated.');
					this.transitionToRoute('apps');
				}, error => {
					this.send('error', error);
				});
			}
		}
	});
});