define('aira-mgmt/accounts/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

    queryParams: {
      q: { refreshModel: true },
      type: { refreshModel: true }
    },

    state: Ember.inject.service(),
    notify: Ember.inject.service(),
    platform: Ember.inject.service(),

    model(params) {
      console.log(params);
      if (params.q && params.type) {
        return Ember.RSVP.hash({
          account: new Ember.RSVP.Promise((resolve, reject) => {
            this.get('platform').doGet('search/account?type=' + encodeURIComponent(params.type) + '&q=' + encodeURIComponent(params.q)).then(response => {
              resolve(response.users);
            }, ({ errorCode, errorMessage }) => {
              reject({ errorCode, errorMessage });
              return [];
            });
          })
        });
      } else {
        return [];
      }
    },

    afterModel(accounts) {
      if (accounts.account) {
        this.set('state.ariaLive', accounts.account.length + ' results.');
      }
    },

    setupController(controller, model) {
      this._super(controller, model);
      controller.set('users', this.get('users'));
      controller.set('search', controller.get('q'));
      controller.set('accountType', "");
      if (controller.get('q') !== null && model.length < 1) {
        this.get('notify').error('No Account was found');
      }
    },

    actions: {
      addNewAccount() {
        console.log('add new account pressed');
        this.transitionTo('accounts.new');
      },

      loading(transition) {
        let controller = this.get('controller');
        if (controller) {
          controller.set('isSearching', true);
          transition.promise.finally(function () {
            controller.set('isSearching', false);
          });
        }
      },

      searchByAccountName() {
        console.log('search by account name pressed');
        const searchType = "ACCOUNT_NAME";
        let query = "?";

        //Checks if the first name search bar has a non-empty value
        if (this.get("controller.accountName")) {
          query = this.get("controller.accountName");
          //if it has a valid value reassign firstName variable
        }
        this.transitionTo('accounts', {
          queryParams: {
            q: query,
            type: searchType
          }
        });
      },

      searchByAccountCode() {
        console.log('search by account code pressed');
        const searchType = "ACCOUNT_CODE";
        let query = "?";

        //Checks if the first name search bar has a non-empty value
        if (this.get("controller.accountCode")) {
          query = this.get("controller.accountCode");
          //if it has a valid value reassign firstName variable
        }

        this.transitionTo('accounts', {
          queryParams: {
            q: query,
            type: searchType
          }
        });
      },

      searchByAccountType() {
        console.log('search by account type pressed');
        const searchType = "ACCOUNT_TYPE";
        const acctType = this.get('controller').accountType;

        this.transitionTo('accounts', {
          queryParams: {
            q: acctType,
            type: searchType
          }
        });
      },

      searchByAccountID() {
        console.log('search by account id pressed');
        const searchType = "ACCOUNT_ID";
        let query = "?";
        if (this.get("controller.accountId")) {
          query = this.get("controller.accountId");
          //if it has a valid value reassign firstName variable
        }
        this.transitionTo('accounts', {
          queryParams: {
            q: query,
            type: searchType
          }
        });
      },

      searchByAccountSiteName() {

        const accountSiteNameQuery = this.get("controller.accountSiteName");
        console.log('search by account site name pressed, account site name query: ' + accountSiteNameQuery);
        if (accountSiteNameQuery) {
          this.transitionTo('accounts', {
            queryParams: {
              q: accountSiteNameQuery,
              type: "ACCOUNT_SITE_NAME"
            }
          });
        }
      },

      searchByAccountSiteAddress() {

        const accountSiteAddressQuery = this.get("controller.accountSiteAddress");
        console.log('search by account site address pressed, account site address query: ' + accountSiteAddressQuery);
        if (accountSiteAddressQuery) {
          this.transitionTo('accounts', {
            queryParams: {
              q: accountSiteAddressQuery,
              type: "ACCOUNT_SITE_ADDRESS"
            }
          });
        }
      },

      select(account) {
        this.controller.set('activeId', account.accountId);
        try {
          let activeTab = this.controllerFor('accounts.account').get('activeTab');
          // Don't pass whole model because it's currently incomplete.
          // This will trigger a call to the api that fetches the whole model
          this.transitionTo('accounts.account.' + activeTab, account.accountId);
        } catch (e) {
          // Just means no user had been selected yet so there's
          // no controller initialized so there's no activeTab to maintain.
          // Don't pass whole model because it's currently incomplete.
          // This will trigger a call to the api that fetches the whole model
          console.log("Trying to go to accounts but caught something ", e);
          this.transitionTo('accounts.account', account.accountId);
        }
      },

      /**
       *
       * @param {String} searchType
       * This method takes a string when it's called by one of the toolbar buttons
       * That String will denote which search bars we want to display
       */
      displaySearch(searchType) {
        const setOfSearchDisplays = new Set();
        setOfSearchDisplays.add(document.getElementById("searchAccountNames"));
        setOfSearchDisplays.add(document.getElementById("searchByAccountID"));
        setOfSearchDisplays.add(document.getElementById("searchByAccountCode"));
        setOfSearchDisplays.add(document.getElementById("searchByAccountType"));
        setOfSearchDisplays.add(document.getElementById("searchByAccountSiteName"));
        setOfSearchDisplays.add(document.getElementById("searchByAccountSiteAddress"));

        for (let searchDisplay of setOfSearchDisplays) {
          if (searchType === searchDisplay.id) {
            searchDisplay.style.display = "block";
          } else {
            searchDisplay.style.display = "none";
          }
        }
      }
    }
  });
});