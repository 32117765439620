define('aira-mgmt/services/state', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({

		users: {

			user: {
				contacts: {
					// Selected table row
					selectedContactId: -1
				},

				address: {
					// Selected table row
					selectedAddressId: -1
				},

				wifi: {
					// Selected table row
					selectedWifiProfileId: -1
				},

				questions: {
					selectedAnswerId: -1
				},

				support: {
					mqttConnected: false
				},

				properties: {
					property: {
						selectedPropertyValueId: -1
					}
				}
			}
		},

		// This flag is toggled by the ModelEditor mixin to indicate unsaved changes.
		// Routes use this flag in willTransition() hooks to pause transitions for confirmation.
		isDirty: false,

		ariaLive: ''
	});
});