define('aira-mgmt/helpers/min-effective-to', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.minEffectiveTo = minEffectiveTo;
  function minEffectiveTo([access]) {
    if (access.userType !== 'business') {
      return '';
    } else if (access.effectiveTo === null) {
      return 'No end date';
    }
    return access.effectiveTo;
  }

  exports.default = Ember.Helper.helper(minEffectiveTo);
});