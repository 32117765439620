define('aira-mgmt/accounts/account/products/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    platform: Ember.inject.service(),

    model() {
      const accountId = this.modelFor('accounts.account').account.accountId;
      return Ember.RSVP.hash({
        product: new Ember.RSVP.Promise((resolve, reject) => {
          this.get('platform').doGet('mgmt/account/' + accountId + '/product').then(response => {
            console.log('response data:', response);
            resolve(response);
          }, ({ errorCode, errorMessage }) => {
            reject({ errorCode, errorMessage });
          });
        }),
        promotion: new Ember.RSVP.Promise((resolve, reject) => {
          this.get('platform').doGet('mgmt/account/' + accountId + '/promotion').then(response => {
            console.log('response data:', response);
            resolve(response);
          }, ({ errorCode, errorMessage }) => {
            reject({ errorCode, errorMessage });
          });
        })
      });
    },

    actions: {

      selectProduct(accountId, product, productOrPromotion) {
        console.log(`Selected ${productOrPromotion}: ${product.id} in account: ${accountId}`);
        this.controller.set('activeId', product.id);
        this.transitionTo('accounts.account.products.product.edit', accountId, product.id);
      },

      createNew() {
        console.log('add product pressed');
        this.transitionTo('accounts.account.products.product.new');
      }
    },

    setupController(controller, model) {
      this._super(controller, model);
      controller.set('timezone', new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]);
    }
  });
});