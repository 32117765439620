define('aira-mgmt/services/firebase', ['exports', '@firebase/app', '@firebase/auth', 'aira-mgmt/config/environment'], function (exports, _app, _auth, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    firebaseApp: Ember.computed(function () {
      return (0, _app.initializeApp)({
        apiKey: _environment.default.APP.firebase_api_key,
        authDomain: _environment.default.APP.firebase_auth_domain,
        projectId: _environment.default.APP.firebase_project_id,
        appId: _environment.default.APP.firebase_app_id
      });
    }),

    firebaseAuth: Ember.computed(function () {
      return (0, _auth.getAuth)(this.get('firebaseApp'));
    }),

    user: null,

    login() {
      return (0, _auth.signInWithRedirect)(this.get('firebaseAuth'), new _auth.OAuthProvider('oidc.okta'));
    },

    handleAuthentication() {
      if (_environment.default.environment === 'development') {
        // HACK: When using localhost, supporting sign-in with redirect is going
        // to take effort that we don't have time for (see
        // https://github.com/firebase/firebase-js-sdk/issues/7342). So, since
        // accessibility is less of an issue while developing, we're using sign-in
        // with popup instead.
        return (0, _auth.signInWithPopup)(this.get('firebaseAuth'), new _auth.OAuthProvider('oidc.okta'));
      }
      return (0, _auth.getRedirectResult)(this.get('firebaseAuth'));
    },

    loginWithCustomToken(customToken) {
      return this.logout().then(() => (0, _auth.signInWithCustomToken)(this.get('firebaseAuth'), customToken)).then(userCredential => this.set('user', userCredential.user)).then(() => console.log('login successful', this.get('user').uid)).catch(error => console.error('login failed', error));
    },

    getIdToken() {
      return this.get('user').getIdToken();
    },

    logout() {
      return (0, _auth.signOut)(this.get('firebaseAuth')).then(() => this.set('user', null));
    }
  });
});