define('aira-mgmt/users/user/referrals/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    notify: Ember.inject.service(),

    platform: Ember.inject.service(),

    model() {
      let self = this;
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.get('platform').doGet('user/referrals/' + this.modelFor('users.user').id).then(response => {
          resolve(Ember.A(response.referrals));
        }, ({ errorCode, errorMessage }) => {
          reject({ errorCode, errorMessage });
        });
      });
    },

    setupController(controller, model) {
      this._super(controller, model);
      controller.set('refereeEmail', null);
      new Ember.RSVP.Promise((resolve, reject) => {
        this.get('platform').doGet('user/' + this.modelFor('users.user').id + '/property/referralCode/value').then(response => {
          controller.set('userReferralCode', response.payload[0].value);
        }, ({ errorCode, errorMessage }) => {
          reject({ errorCode, errorMessage });
        });
      });
    },

    actions: {
      sendReferralFromUserAccount() {
        let userReferralCode = this.controller.get("userReferralCode");
        let refereeEmail = this.controller.get("refereeEmail");
        if (userReferralCode && refereeEmail) {
          new Ember.RSVP.Promise((resolve, reject) => {
            this.get('platform').doPost('user/referral', {
              referralCode: userReferralCode,
              referralEmail: refereeEmail
            }).then(response => {
              this.get('notify').success('Sent referral to ' + refereeEmail + ' from user account.');
              this.refresh();
            }, ({ errorCode, errorMessage }) => {
              this.get('notify').error(errorMessage);
            });
          });
        } else {
          this.get('notify').error('No referral code or refereeEmail; ignoring sending referral');
        }
      }
    }
  });
});