define('aira-mgmt/accounts/account/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.removeNotSetLocalizations = removeNotSetLocalizations;
  exports.addAllLangLocalizations = addAllLangLocalizations;
  exports.addMissingLangLocalizations = addMissingLangLocalizations;
  exports.validateEnglishLocalization = validateEnglishLocalization;
  exports.validateLocalization = validateLocalization;
  exports.validateAttributesLocalized = validateAttributesLocalized;
  exports.default = Ember.Route.extend({

    initialTab: null,

    platform: Ember.inject.service(),
    beforeModel(t) {
      // Handles setting the activeTab when deep-linking
      // targetName is: users.user.{activeTab}.index
      this.initialTab = t.targetName.split('.')[2];
    },

    model: function (p) {
      return Ember.RSVP.hash({
        account: new Ember.RSVP.Promise((resolve, reject) => {
          this.get('platform').doGet('search/account?type=ACCOUNT_ID&q=' + p.account_id).then(response => {
            resolve(response.users[0]);
          }, ({ errorCode, errorMessage }) => {
            reject({ errorCode, errorMessage });
            return [];
          });
        })

      });
    },

    setupController: function (controller, model) {
      this._super(controller, model);
      controller.set('activeTab', this.initialTab);
      this.modelFor("accounts.account").account.isBusiness = model.account.accountType === "BUSINESS" ? true : false;
    },

    actions: {
      loading(transition) {
        let controller = this.get('controller');
        if (controller) {
          controller.set('isLoading', true);
          transition.promise.finally(function () {
            controller.set('isLoading', false);
          });
        }
      },

      willTransition(t) {
        // Handles keeping the activeTab updated when changing
        // targetName is: accounts.account.{activeTab}.index
        let target = t.targetName.split('.')[2];
        this.controller.set('activeTab', target);
      }
    }
  });
  function removeNotSetLocalizations(attributesLocalized) {

    for (const [language, attr] of Object.entries(attributesLocalized)) {
      if (!attr.localization.name && !attr.localization.description && !attr.localization.explorerMessage) {
        delete attributesLocalized[language];
      }
    }

    return attributesLocalized;
  }

  function addAllLangLocalizations() {

    // TODO: we would benefit here from a list of supported languages
    // - the list can be returned as part of this API or a new API to get languages only
    // - alternative would be that this API would return these default structures which seems dirty
    let attributesLocalized = {};
    attributesLocalized.English = defaultLocalizationStructure();
    attributesLocalized.French = defaultLocalizationStructure();
    attributesLocalized.Spanish = defaultLocalizationStructure();

    return attributesLocalized;
  }

  function addMissingLangLocalizations(attributesLocalized) {

    // when localizations are not present, initialize with default json
    // TODO: we would benefit here from a list of supported languages
    // - the list can be returned as part of this API or a new API to get languages only
    // - alternative would be that this API would return these default structures which seems dirty
    if (!('English' in attributesLocalized)) {
      attributesLocalized.English = defaultLocalizationStructure();
    }
    if (!('French' in attributesLocalized)) {
      attributesLocalized.French = defaultLocalizationStructure();
    }
    if (!('Spanish' in attributesLocalized)) {
      attributesLocalized.Spanish = defaultLocalizationStructure();
    }

    console.log('attr: ', attributesLocalized);

    return attributesLocalized;
  }

  function defaultLocalizationStructure() {
    return { id: null, localization: { name: null, description: null, explorerMessage: null } };
  }

  // English localization is mandatory, should always be present, never deleted
  function validateEnglishLocalization(localization) {

    let dataIsGood = true;
    if (!localization || !localization.name || !localization.explorerMessage) {
      dataIsGood = false;
    }
    return dataIsGood;
  }

  function validateLocalization(localization) {

    let dataIsGood = true;

    if (localization && localization.description && (!localization.name || !localization.explorerMessage) || localization && localization.name && !localization.explorerMessage || localization && localization.explorerMessage && !localization.name) {
      dataIsGood = false;
    }

    return dataIsGood;
  }

  function validateAttributesLocalized(controllerAttrLoc) {

    let dataIsGood = true;
    let errorMessage = [];

    for (const [language, attr] of Object.entries(controllerAttrLoc)) {
      if (language === 'English') {
        if (!validateEnglishLocalization(attr.localization)) {
          dataIsGood = false;
          errorMessage.push('English Product Name and Explorer Message cannot be empty');
        }
      } else {
        if (!validateLocalization(attr.localization)) {
          dataIsGood = false;
          errorMessage.push(`${language} Product Name and Explorer Message cannot be empty`);
        }
      }
    }

    return [dataIsGood, errorMessage];
  }
});