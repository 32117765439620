define('aira-mgmt/users/user/properties/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),

    notify: Ember.inject.service(),

    platform: Ember.inject.service(),

    model() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        /**
         * Gets all properties, and all properties set on a user and merges the values from
         * the properties set on the user into the list of all properties. This allows us
         * to display all properties for updating, even if there is no value for the user.
         */
        Ember.RSVP.hash({
          properties: this.get('platform').doGet('user/property'),
          values: this.get('platform').doGet('user/' + this.modelFor('users.user').id + '/property/value')
        }).then(response => {
          let properties = response.properties.payload;
          let values = response.values.payload;
          let propsUnordered = {};
          let propsOrdered = {};

          // console.log('properties', properties);
          // console.log('values', values);

          properties.forEach(prop => {
            let pv = values[prop.key];
            if (pv) prop['value'] = pv.map(v => v.value);
            propsUnordered[prop.key] = prop;
            propsUnordered[prop.key]['childrenList'] = [];
            prop.children.forEach(child => {
              let moreChildren = this.getChildren(child);
              Object.keys(moreChildren).forEach(key => {
                propsUnordered[prop.key]['childrenList'].push(moreChildren[key].name);
              });
              propsUnordered = Object.assign(propsUnordered, moreChildren);
            });
          });

          Object.keys(propsUnordered).sort().forEach(key => {
            propsOrdered[key] = propsUnordered[key];
          });

          resolve(propsOrdered);
        }, ({ errorCode, errorMessage }) => {
          reject({ errorCode, errorMessage });
        });
      });
    },

    setupController(controller, model) {
      this._super(controller, model);
      controller.resetPendingUpdates();
    },

    getChildren(prop) {
      // console.log('getChildren', prop);
      let children = {};
      prop['parent'] = prop.key.split('.')[0];
      prop['depth'] = prop.key.split('.').length - 1;
      children[prop.key] = prop;
      if (prop.hasOwnProperty('children')) {
        prop.children.forEach(child => {
          let moreChildren = this.getChildren(child);
          children = Object.assign(children, moreChildren);
        });
      }
      return children;
    },

    actions: {
      selectRow(key) {
        this.transitionTo('users.user.properties.property', key);
      }
    }
  });
});