define('aira-mgmt/components/table-row/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: 'tr',

		classNameBindings: ['active'],

		active: false,

		/**
   * If true, the row will receive the CSS 'active' class when clicked.
   */
		allowActive: true,

		click: function () {
			if (this.get('allowActive')) {
				this.set('active', true);
			}
			this.sendAction('select');
		}
	});
});