define('aira-mgmt/users/user/subscription/minutes/route', ['exports', 'accounting/format-money'], function (exports, _formatMoney) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    notify: Ember.inject.service(),

    platform: Ember.inject.service(),

    setupController(controller, model) {
      this._super(controller, model);
      controller.set('userId', this.modelFor('users.user').get('id'));
      controller.set('calculating', false);
      controller.set('calculated', false);
      controller.set('purchaseOption', null);

      let purchaseOptions = [];
      model.orderOptions.getWithDefault('minuteAddOnAdjustments', []).forEach(addon => {
        let price = Math.abs(addon.price) >= 100 ? addon.price / 100 : addon.price;
        price = (0, _formatMoney.default)(price, addon.currency, 2, ',', '.', '%v %s');

        purchaseOptions.push({
          id: addon.code,
          text: addon.minutes + ' minutes for ' + price
        });
      });
      controller.set('purchaseOptions', purchaseOptions);
    },

    actions: {
      cancel() {
        this.transitionTo('users.user.subscription');
      },

      save(addOnCode) {
        if (addOnCode == null) {
          return;
        }

        this.set('controller.inProgress', true);

        let overrideBundleQuantity = this.get('controller.overrideBundleQuantity');
        let overridePrice = this.get('controller.overridePrice');

        let cvv = null;
        if (overridePrice === null || overridePrice.toString() !== '0') {
          let billingInfo = this.get('controller.billingInfo');
          cvv = prompt("Please provide the CVV for the " + billingInfo.cardType + " ending in " + billingInfo.lastFour);
          let isError = false;

          if (cvv === null) {
            isError = true;
          } else if (isNaN(cvv)) {
            this.get('notify').error('CVV contains non-number characters');
            isError = true;
          } else if (cvv.length !== 3 && cvv.length !== 4) {
            this.get('notify').error('CVV number is the incorrect length');
            isError = true;
          }

          if (isError) {
            this.set('controller.inProgress', false);
            return;
          }
        }

        this.get('platform').doPost('care/order/' + this.get('controller.userId') + '/addon', {
          "addOnCode": addOnCode,
          "cvv": cvv,
          'overrideBundleQuantity': overrideBundleQuantity,
          'overridePrice': overridePrice
        }).then(response => {
          this.set('controller.inProgress', false);
          this.get('notify').success('Minutes Purchased!');
          this.transitionTo('users.user.subscription', { queryParams: { refreshModel: true } });
        }, error => {
          this.set('controller.inProgress', false);
          this.send('error', error);
        });
      }
    }
  });
});