define('aira-mgmt/components/account-product-promotion/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    state: Ember.inject.service(),

    platform: Ember.inject.service(),

    notify: Ember.inject.service(),

    isPromotion: false,

    product: null,

    actions: {
      save() {
        // let updatedProduct = this.get('product');
        console.log('saved pressed with updatedProduct', updatedProduct);
        // this.get('platform').doPut(['mgmt','account', 'update'].join('/'), {
        //   accountId: updatedProduct.accountId,
        //   businessType: updatedProduct.businessType,
        //   name: updatedProduct.name,
        //   acceptBusinessUsers: updatedProduct.acceptBusinessUsers,
        // }).then(() => {
        //   this.get('notify').success('Account profile was updated!');
        // }, (error) => {
        //   this.send('error', error);
        // });
      }
    }

  });
});