define('aira-mgmt/users/user/address/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),

    setupController(controller, model) {
      this._super(controller, model);
      // Provide state service to generated controller, avoids making our own.
      controller.set('state', this.get('state'));
    },

    actions: {
      // received from table row click
      selectAddress(address) {
        this.transitionTo('users.user.address.edit', address);
      }
    }
  });
});