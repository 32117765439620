define('aira-mgmt/users/user/onboard/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    notify: Ember.inject.service(),

    platform: Ember.inject.service(),

    model() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.get('platform').doGet('user/' + this.modelFor('users.user').id + '/question?category=management').then(response => {
          resolve(response.payload);
        }, ({ errorCode, errorMessage }) => {
          reject({ errorCode, errorMessage });
        });
      });
    },

    setupController(controller, model) {
      this._super(controller, model);
      controller.resetPendingUpdates();
    },

    /*
     [
     {"key":"mobilityTool","values":[{"value":"Guide Dog"}]},
     {"key":"mobilityTool.guideDog","values":[{"value":"boss"}]},
     {"key":"orientationPref","values":[{"value":"Left & Right"}]},
     {"key":"escalator","values":[{"value":"true"}]}
     ]
     */
    actions: {
      save(pendingUpdates) {
        this.set('controller.inProgress', true);

        const payload = Object.keys(pendingUpdates).map(key => {
          let values;
          if (Ember.typeOf(pendingUpdates[key]) === 'string') {
            values = pendingUpdates[key] === '' ? [] : [{ value: pendingUpdates[key] }];
          } else if (Ember.typeOf(pendingUpdates[key]) === 'boolean') {
            values = { value: pendingUpdates[key] ? 'true' : 'false' };
          } else {
            values = pendingUpdates[key].map(answer => ({ value: answer }));
          }
          return { key, values };
        });

        this.get('platform').doPost('user/' + this.modelFor('users.user').id + '/property/value/batch', payload).then(() => {
          this.get('notify').success('Profile Successfully Changed!');
          this.get('controller').resetPendingUpdates();
        }, error => {
          this.send('error', error);
        }).finally(() => {
          this.set('controller.inProgress', false);
        });
      }
    }
  });
});