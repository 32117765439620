define('aira-mgmt/helpers/change-PST-to-ISO', ['exports', 'luxon'], function (exports, _luxon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.changePSTtoISO = changePSTtoISO;
  function changePSTtoISO([date]) {
    if (!date || date === '') return '';

    const parsedOnlyDate = _luxon.DateTime.fromFormat(date, 'yyyy-MM-dd', { zone: 'America/Los_Angeles' });
    if (parsedOnlyDate.isValid) {
      return date;
    }

    const parsedDate = _luxon.DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm', { zone: 'America/Los_Angeles' });

    if (!parsedDate.isValid) {
      return date;
    }

    return parsedDate.setZone('UTC').toISO({ suppressMilliseconds: true });
  }

  exports.default = Ember.Helper.helper(changePSTtoISO);
});