define('aira-mgmt/components/properties/property-property/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    p: null,

    update: () => {},

    init() {
      this._super(...arguments);
      let p = this.get('p');
      if (p.hasOwnProperty('property')) {
        p['type'] = p.property.type;
      }
      this.set('p', p);
    },

    actions: {
      update(p, value) {
        this.update(p, value);
      }
    }
  });
});