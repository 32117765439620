define('aira-mgmt/users/user/properties/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    pendingUpdates: {},

    inProgress: false,

    resetPendingUpdates() {
      this.set('pendingUpdates', {});
    },

    actions: {
      update(p, input) {
        this.get('pendingUpdates')[p.key] = input;
      }
    }
  });
});