define('aira-mgmt/adapters/application', ['exports', 'ember-simple-auth/mixins/data-adapter-mixin', 'aira-mgmt/config/environment', 'ember-data'], function (exports, _dataAdapterMixin, _environment, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {

		authorizer: 'authorizer:platform',

		host: _environment.default.APP.host,
		namespace: _environment.default.APP.namespace,

		session: Ember.inject.service('session'),

		platform: Ember.inject.service(),

		/**
   * This function is a volatile() computed property,
   * meaning that its value is recomputed with each
   * API call.
   */
		headers: Ember.computed(function () {
			const headers = {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'X-API-Key': _environment.default.APP.X_API_Key,
				'X-Aira-Token': this.get('session.data.authenticated.token'),
				'X-User-Id': this.get('session.data.authenticated.id'),
				'X-Client-Id': _environment.default.APP.X_Client_Id,
				'X-Trace-Id': this._generateId()
			};

			return headers;
		}).volatile(),

		_encodeUrl(login) {
			return encodeURIComponent(login);
		},

		_generateId() {
			// Large number without leading zeroes
			return Math.floor(100000000 + Math.random() * 900000000);
		},

		/**
   * Replaced default to allow adhoc headers via ajax() options
    **/
		ajaxOptions(url) {
			const hash = this._super(...arguments);

			const headersField = Ember.get(this, 'headers');
			if (headersField !== undefined && !Ember.isNone(hash.headers)) {
				let headers = {};
				Ember.merge(headers, headersField);
				Ember.merge(headers, hash.headers);
				hash.beforeSend = function (xhr) {
					Object.keys(headers).forEach(key => xhr.setRequestHeader(key, headers[key]));
				};
			}

			return hash;
		},

		/**
   * Replaced default which used PATCH
    **/
		updateRecord(store, type, snapshot) {
			const data = {};
			const serializer = store.serializerFor(type.modelName);

			serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

			const id = snapshot.id;
			const url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

			// return this.ajax(url, 'PUT', { data: data });
			return this.platform.doPutRawUrl(url, data);
		}
	});
});