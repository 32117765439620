define("aira-mgmt/helpers/contains", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.contains = contains;
  /**
   * {{contains searchItem arrayOfItemsToSearch}}
   * @param params
   * @returns {boolean} True if found
   */
  function contains(params /*, hash*/) {
    return params[1] && params[1].length > 0 && params[1].indexOf(params[0]) !== -1;
  }

  exports.default = Ember.Helper.helper(contains);
});