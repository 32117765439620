define('aira-mgmt/components/user-profile/component', ['exports', 'aira-mgmt/mixins/model-editor'], function (exports, _modelEditor) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	let profilePropNames = ['id', 'status', 'firstName', 'lastName', 'phoneticFirstName', 'login', 'password', 'language', 'gender', 'dateOfBirth', 'phoneNumber', 'phoneVerified', 'properties'];

	exports.default = Ember.Component.extend(_modelEditor.default, {

		state: Ember.inject.service(),

		profile: null,

		onSave: null,

		onCancel: null,

		onNext: null,

		autofocusFirstName: false,

		disableEmail: true,

		showPassword: false, // defaulted to false, because returned password is hashed

		data: null,

		isAiShareAuthSubscribed: Ember.computed('buildAiProgramJoined', function () {
			const buildAiProgramJoined = this.get('profile').get('buildAiProgramJoined');
			if (buildAiProgramJoined === null) {
				return 'null';
			}
			return buildAiProgramJoined.toString();
		}),

		isShareSelectDisabled: Ember.computed('buildAiProgramJoined', function () {
			return this.get('profile.buildAiProgramJoined') !== true;
		}),

		init() {
			this._super(...arguments);
			this._bindModelEditor('data', 'profile', profilePropNames);
			let languagesSelected = [];
			for (let language of this.get('profile').get('language')) {
				for (let languageOption of this.get("languageOptions")) {
					if (languageOption.id === language) {
						languagesSelected.push(languageOption);
					}
				}
			}
			this.set('languageSelected', languagesSelected);
		},

		didUpdateAttrs() {
			this._super(...arguments);
			let languagesSelected = [];
			for (let language of this.get('profile').get('language')) {
				for (let languageOption of this.get("languageOptions")) {
					if (languageOption.id === language) {
						languagesSelected.push(languageOption);
					}
				}
			}
			this.set('languageSelected', languagesSelected);
		},

		actions: {
			selectLanguage(languages) {
				this.set('data.language', languages.map(l => l.id));
				this.set('languageSelected', languages);
			},
			selectBuildAiProgramJoined(buildAiProgramJoined) {
				const buildAiProgramJoinedProp = this.get('data').getProperties(['properties']);
				buildAiProgramJoinedProp.properties['buildAiProgramJoined'][0]['value'] = Boolean(buildAiProgramJoined === `true`);
				this.set('data.properties', buildAiProgramJoinedProp.properties);
			},
			selectStatus(status) {
				this.set('data.status', status);
			},
			selectGender(gender) {
				this.set('data.gender', gender);
			},
			save() {
				const profileProps = this.get('data').getProperties(profilePropNames);
				this.get('onSave')(this.get('profile'), profileProps);
			},

			next() {
				const profileProps = this.get('data').getProperties(profilePropNames);
				this.get('onNext')(this.get('profile'), profileProps);
			},

			cancel() {
				this.get('onCancel')();
			}
		},

		statusOptions: [{ text: 'Active', id: 'Active' }, { text: 'Inactive', id: 'Inactive' }, { text: 'Suspended', id: 'Suspended' }, { text: 'Test Mode', id: 'Test Mode' }],

		genderOptions: [{ text: 'Male', id: 'male' }, { text: 'Female', id: 'female' }, { text: 'Gender Fluid', id: 'gender-fluid' }, { text: 'Not Disclosed', id: 'not-disclosed' }],

		buildAiProgramJoinedOptions: [{ text: 'Yes', id: 'true' }, { text: 'No', id: 'false' }],

		languageOptions: [{ text: "English", id: "English" }, { text: "French", id: "French" }, { text: "Spanish", id: "Spanish" }],
		languageSelected: null
	});
});