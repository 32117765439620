define('aira-mgmt/helpers/active', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.active = active;
  function active(params /*, hash*/) {
    const activeTab = params[0];
    if (params.slice(1).any(tab => activeTab === tab)) {
      return 'active';
    }
  }

  exports.default = Ember.Helper.helper(active);
});