define('aira-mgmt/components/select-2/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['form-group'],

		/* The label value */
		label: null,

		/* All possible options as array of {id, text} */
		options: null,

		/* All current selections as array of id */
		selections: null,

		/* Handler when selection added receiving (id) */
		onSelect: null,

		/* Handler when selection removed receiving (id) */
		onUnselect: null,

		didInsertElement() {
			const self = this;

			this.$('.select-icons').select2({
				minimumResultsForSearch: Infinity,
				escapeMarkup: function (m) {
					return m;
				},
				width: '100%',
				data: this.get('options')
			}).on('select2:select', function (e) {
				self.get('onSelect')(e.params.data.id);
			}).on('select2:unselect', function (e) {
				self.get('onUnselect')(e.params.data.id);
			});
		},

		didRender() {
			this.$('.select-icons').val(this.get('selections')).trigger('change.select2');
		},

		willDestroyElement() {
			this.$('.select-icons').off('select2:select').off('select2:unselect');
		}
	});
});