define('aira-mgmt/adapters/service', ['exports', 'aira-mgmt/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    platform: Ember.inject.service(),

    query(store, type, query) {
      console.log(query);
      const url = `user/service/history/bu`;
      return this.platform.doGet(url);
    }
  });
});