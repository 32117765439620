define('aira-mgmt/components/wifi-profile/component', ['exports', 'aira-mgmt/mixins/model-editor'], function (exports, _modelEditor) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	let wifiPropNames = ['userId', 'profileId', 'priority', 'ssid', 'securityType', 'secretKey', 'type'];

	exports.default = Ember.Component.extend(_modelEditor.default, {

		state: Ember.inject.service(),

		title: null,

		profile: null,

		onSave: null,

		onCancel: null,

		onDelete: null,

		data: null,

		keyNotRequired: Ember.computed('data.securityType', function () {
			if (this.get('data.securityType') === 'None') {
				this.set('data.secretKey', '');
				return true;
			}
			return false;
		}),

		init() {
			this._super(...arguments);
			this._bindModelEditor('data', 'profile', wifiPropNames);
		},

		actions: {
			selectSecurityType(securityType) {
				this.set('data.securityType', securityType);
			},

			selectType(type) {
				this.set('data.type', type);
			},

			save() {
				const wifiProps = this.get('data').getProperties(wifiPropNames);
				this.get('onSave')(this.get('profile'), wifiProps);
			},

			cancel() {
				this.set('state.isDirty', false);
				this.get('onCancel')(this.get('profile'));
			},

			delete() {
				if (confirm('Are you sure?')) {
					this.get('onDelete')(this.get('profile'));
				}
			}
		},

		securityOptions: [{ text: 'None', id: 'None' }, { text: 'WEP', id: 'WEP' }, { text: 'WPA', id: 'WPA' }, { text: 'WPA2', id: 'WPA2' }, { text: 'WPA2_PSK', id: 'WPA2_PSK' }],

		typeOptions: [{ text: 'WIFI', id: 'WIFI' }, { text: 'HOTSPOT', id: 'HOTSPOT' }, { text: 'MIFI', id: 'MIFI' }]
	});
});