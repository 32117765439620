define('aira-mgmt/utils/validate-dates', ['exports', 'luxon'], function (exports, _luxon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.validateDate = validateDate;
  function validateDate(dateValue, fieldName, isRequired = false) {
    if (dateValue == null || dateValue === '') {
      return isRequired ? `Missing ${fieldName}` : null;
    }

    const parseOnlyDate = _luxon.DateTime.fromFormat(dateValue, 'yyyy-MM-dd', { zone: 'America/Los_Angeles' });
    if (parseOnlyDate.isValid) {
      return `Invalid ${fieldName} date`;
    }

    const dateObject = _luxon.DateTime.fromFormat(dateValue, 'yyyy-MM-dd HH:mm', { zone: 'America/Los_Angeles' });

    if (!dateObject.isValid) {
      return `Invalid ${fieldName} date`;
    }

    return null;
  }
});