define('aira-mgmt/users/user/service/route', ['exports', 'aira-mgmt/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    queryParams: {
      pg: {
        refreshModel: true
      }
    },

    platform: Ember.inject.service(),

    model(params) {
      let id = this.modelFor('users.user').get('id');
      let pg = params.pg == null ? 0 : params.pg;

      return Ember.RSVP.hash({
        user: this.get('store').findRecord('user', id),
        service: new Promise((resolve, reject) => {
          this.get('platform').doGet('user/' + id + '/service-request?&page=' + pg + '&includeTestCalls=true').then(services => {
            services.payload.forEach(service => {
              service.isEditShareSession = false;
            });
            resolve(Ember.Object.create(services));
          }, ({ errorCode, errorMessage }) => {
            reject({ errorCode, errorMessage });
          });
        })
      });
    },

    setupController(controller, model) {
      this._super(controller, model);
      if (controller.get('pg') == null) {
        controller.set('pg', 0);
      }
      controller.set('timezone', new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]);
    },

    actions: {
      selectServiceRequest(request) {
        window.open(_environment.default.APP.ssp + "/internal/report?serviceID=" + request.id, "_blank");
      },
      forceRefreshUserService() {
        this.refresh();
      }
    }
  });
});