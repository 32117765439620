define('aira-mgmt/accounts/account/sites/new/route', ['exports', 'aira-mgmt/accounts/account/route', 'aira-mgmt/utils/validate-dates', 'aira-mgmt/helpers/change-PST-to-ISO'], function (exports, _route, _validateDates, _changePSTToISO) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Route.extend({

    notify: Ember.inject.service(),
    platform: Ember.inject.service(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.set('controller.attributesLocalized', (0, _route.addAllLangLocalizations)());
    },


    actions: {
      cancelNewSite: function cancelNewSite() {
        var accountId = this.modelFor('accounts.account').account.accountId;
        this.transitionTo('accounts.account.sites', accountId);
      },
      createSite: function createSite() {
        var _this = this;

        var accountId = this.modelFor('accounts.account').account.accountId;
        // check site information
        var dataIsGood = true;
        var errorMessage = [];
        if (this.get('controller.agentMessage') === '' || this.get('controller.agentMessage') == null) {
          dataIsGood = false;
          errorMessage.push('Missing agent message');
        }

        var effectiveFromError = (0, _validateDates.validateDate)(this.get('controller.effectiveFrom'), 'effective from', true);
        if (effectiveFromError) {
          dataIsGood = false;
          errorMessage.push(effectiveFromError);
        }

        var effectiveToValue = this.get('controller.effectiveTo');
        if (effectiveToValue !== '' && effectiveToValue != null) {
          var effectiveToError = (0, _validateDates.validateDate)(effectiveToValue, 'effective to', false);
          if (effectiveToError) {
            dataIsGood = false;
            errorMessage.push(effectiveToError);
          }
        }

        if (this.get('controller.address1') === '' || this.get('controller.address1') === undefined) {
          dataIsGood = false;
          errorMessage.push('Missing address line 1');
        }
        if (this.get('controller.city') === '' || this.get('controller.city') === undefined) {
          dataIsGood = false;
          errorMessage.push('Missing city');
        }
        if (this.get('controller.country') === '' || this.get('controller.country') === undefined) {
          dataIsGood = false;
          errorMessage.push('Missing country');
        }
        if (this.get('controller.siteDurationPerCallCheckbox')) {
          if (this.get('controller.durationPerCall') === '' || this.get('controller.durationPerCall') === undefined) {
            dataIsGood = false;
            errorMessage.push('Missing enforced duration per call');
          } else {
            var value = parseInt(this.get('controller.durationPerCall'));
            if (value <= 0 || isNaN(value)) {
              dataIsGood = false;
              errorMessage.push('Duration per call value must be a valid number greater than 0');
            }
          }
        }

        var validationArrayRsp = (0, _route.validateAttributesLocalized)(this.get('controller.attributesLocalized'));
        if (!validationArrayRsp[0]) {
          dataIsGood = false;
          errorMessage.push.apply(errorMessage, _toConsumableArray(validationArrayRsp[1]));
        }

        if (dataIsGood) {
          var serviceDomainValue = [{
            type: 'SLS',
            value: this.get('controller.isASLSite') ? 'true' : 'false'
          }, {
            type: 'VIS',
            value: this.get('controller.isBLVSite') ? 'true' : 'false'
          }];

          this.get('platform').doPost(['access', 'site'].join('/'), {
            account: {
              id: accountId
            },
            accountId: accountId,
            class: 'site',
            type: "public",
            termsAndConditions: this.get('controller.termsAndConditions') ? this.get('controller.termsAndConditions') : null,
            agentMessage: this.get('controller.agentMessage'),
            effectiveFrom: (0, _changePSTToISO.changePSTtoISO)([this.get('controller.effectiveFrom')]),
            effectiveTo: this.get('controller.effectiveTo') ? (0, _changePSTToISO.changePSTtoISO)([this.get('controller.effectiveTo')]) : null,
            siteAddress: {
              accountId: accountId,
              address1: this.get('controller.address1'),
              address2: this.get('controller.address2') ? this.get('controller.address2') : null,
              city: this.get('controller.city'),
              state: this.get('controller.state'),
              country: this.get('controller.country'),
              zip: this.get('controller.zip'),
              addressType: 'site'
            },
            fence: this.get('controller.fence') ? JSON.parse(this.get('controller.fence')) : null,
            durationPerCall: this.get('controller.siteDurationPerCallCheckbox') ? parseInt(this.get('controller.durationPerCall')) : -1,
            enabled: this.get('controller.enabled'),
            requireAgentApproval: this.get('controller.requireAgentApproval'),
            searchable: this.get('controller.searchable'),
            displayable: this.get('controller.displayable'),
            gpsActivated: this.get('controller.gpsActivated'),
            bufferAroundFence: this.get('controller.bufferAroundFence'),
            serviceDomain: serviceDomainValue,
            attributesLocalized: (0, _route.removeNotSetLocalizations)(this.get('controller.attributesLocalized'))
          }).then(function () {
            _this.get('notify').success('Site created!');
            _this.transitionTo('accounts.account.sites', accountId);
          }, function (error) {
            _this.send('error', error.errorMessage);
          });
          this.set('controller.errorMessage', null);
        } else {
          this.set('controller.errorMessage', errorMessage);
        }
      }
    }
  });
});