define('aira-mgmt/accounts/account/usage/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    platform: Ember.inject.service(),

    model() {
      let accountId = this.modelFor('accounts.account').account.accountId;
      return Ember.RSVP.hash({
        usage: new Ember.RSVP.Promise((resolve, reject) => {
          this.get('platform').doGet('mgmt/account/' + accountId + '/usage-state').then(response => {
            resolve(response);
          }, ({ errorCode, errorMessage }) => {
            reject({ errorCode, errorMessage });
            return [];
          });
        })
      });
    },

    setupController(controller, model) {
      this._super(controller, model);
      controller.set('timezone', new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]);
    },

    actions: {}
  });
});