define('aira-mgmt/users/user/activity/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    notify: Ember.inject.service(),

    platform: Ember.inject.service(),

    model(p) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        Ember.RSVP.hash({
          activity: this.get('platform').doGet('mgmt/' + this.modelFor('users.user').id + '/login')
        }).then(response => {
          let activity = response;
          // console.log('activity', activity);
          resolve(activity);
        }, ({ errorCode, errorMessage }) => {
          reject({ errorCode, errorMessage });
        });
      });
    },

    actions: {
      logoutApp(appName) {
        const user = this.modelFor('users.user');
        let fullName = user.get('lastName') != null ? user.get('firstName') + ' ' + user.get('lastName') : user.get('firstName');
        let userId = user.get('id');
        if (confirm('Are you sure you want to logout ' + fullName + ' with user ID ' + userId + ' from ' + appName + '?')) {
          this.get('platform').doPost(['mgmt', userId, 'login', appName, 'logout'].join('/')).then(() => {
            this.get('notify').success('User has been logged out of ' + appName);
            this.transitionTo('users.user');
          }, error => {
            this.send('error', error);
          });
        }
      },
      banApp(appName) {
        const user = this.modelFor('users.user');
        let fullName = user.get('lastName') != null ? user.get('firstName') + ' ' + user.get('lastName') : user.get('firstName');
        let userId = user.get('id');
        if (confirm('Are you sure you want to ban ' + fullName + ' with user ID ' + userId + ' from ' + appName + '?')) {
          this.get('platform').doPost(['mgmt', userId, 'login', appName, 'blacklist'].join('/')).then(() => {
            this.get('notify').success('User has been banned from ' + appName);
            this.transitionTo('users.user');
          }, error => {
            this.send('error', error);
          });
        }
      },
      logoutUser() {
        const user = this.modelFor('users.user');
        // console.log('logoutUser', user);
        let fullName = user.get('lastName') != null ? user.get('firstName') + ' ' + user.get('lastName') : user.get('firstName');
        let userId = user.get('id');
        if (confirm('Are you sure you want to logout ' + fullName + ' with user ID ' + userId + '?')) {
          this.get('platform').doDelete(['mgmt', userId, 'login'].join('/')).then(() => {
            this.get('notify').success('User has been logged out of everything');
            this.transitionTo('users.user');
          }, error => {
            this.send('error', error);
          });
        }
      }
    }
  });
});