define('aira-mgmt/users/user/credit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    notify: Ember.inject.service(),

    platform: Ember.inject.service(),

    session: Ember.inject.service('session'),

    setupController: function (controller, model) {
      this._super(controller, model);
      controller.set('minutesToCredit', null);
      controller.set('internalReason', null);
      controller.set('externalReason', null);
      controller.set('salesforceCaseNumber', null);
    },

    model() {
      return Ember.RSVP.hash({
        history: new Ember.RSVP.Promise((resolve, reject) => {
          this.get('platform').doGet('credit/history?userId=' + this.modelFor('users.user').id).then(response => {
            let list = [];

            response.payload.reverse().forEach(credit => {
              credit['minutes'] = credit['seconds'] / 60;
              list.push(Ember.Object.create(credit));
            });

            resolve(list);
          }, ({ errorCode, errorMessage }) => {
            reject({ errorCode, errorMessage });
          });
        }),
        internalReasons: new Ember.RSVP.Promise((resolve, reject) => {
          this.get('platform').doGet('credit/internal').then(response => {
            resolve(response.payload.map(item => {
              return {
                id: item.reason,
                text: item.reason
              };
            }));
          }, ({ errorCode, errorMessage }) => {
            reject({ errorCode, errorMessage });
          });
        }),
        externalReasons: new Ember.RSVP.Promise((resolve, reject) => {
          this.get('platform').doGet('credit/external').then(response => {
            resolve(response.payload.map(item => {
              return {
                id: item.reason,
                text: item.reason
              };
            }));
          }, ({ errorCode, errorMessage }) => {
            reject({ errorCode, errorMessage });
          });
        })
      });
    },

    actions: {
      credit() {
        const userId = this.modelFor('users.user').get('id');

        let minutesToCredit = this.get('controller.minutesToCredit');
        let internalReason = this.get('controller.internalReason');
        let externalReason = this.get('controller.externalReason');
        let salesforceCaseNumber = this.get('controller.salesforceCaseNumber');
        if (minutesToCredit && $.isNumeric(minutesToCredit) && internalReason && externalReason) {
          this.get('platform').doPost(['mgmt', userId, 'credit'].join('/'), {
            minutes: minutesToCredit,
            internalReason: internalReason,
            externalReason: externalReason,
            salesforceCaseNumber: salesforceCaseNumber
          }).then(() => {
            this.get('notify').success('Minutes were credited!');
            this.transitionTo('users.user');
          }, error => {
            this.send('error', error);
          });
        }
      }
    }
  });
});