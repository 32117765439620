define('aira-mgmt/adapters/app', ['exports', 'aira-mgmt/adapters/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({

		platform: Ember.inject.service(),

		query(store, type, query) {
			let url = `app/version?requestSource=${query.filter.requestSource}`;
			return this.platform.doGet(url);
		},

		urlForQuery() {
			return [this.host, this.namespace, 'app', 'version'].join('/');
		},

		urlForUpdateRecord() {
			return [this.host, this.namespace, 'app', 'version'].join('/');
		}
	});
});