define('aira-mgmt/accounts/account/users/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),

    notify: Ember.inject.service(),

    platform: Ember.inject.service(),

    beforeModel() {},

    model() {
      return Ember.RSVP.hash({
        primary: new Ember.RSVP.Promise((resolve, reject) => {
          let accountId = this.modelFor('accounts.account').account.accountId;
          let accountType = "PRIMARY";
          this.get('platform').doGet('mgmt/account/' + accountId + '/' + accountType).then(response => {
            resolve(response.payload);
          }, ({ errorCode, errorMessage }) => {
            reject({ errorCode, errorMessage });
          });
        }),
        users: new Ember.RSVP.Promise((resolve, reject) => {
          const accountId = this.modelFor('accounts.account').account.accountId;
          const accountType = this.modelFor('accounts.account').account.accountType;
          let accountToSearchFor = null;
          if (accountType === 'INDIVIDUAL') {
            accountToSearchFor = 'SECONDARY';
          } else if (accountType === 'BUSINESS') {
            accountToSearchFor = 'BUSINESS';
          }

          if (accountToSearchFor !== null) {
            this.get('platform').doGet('mgmt/account/' + accountId + '/' + accountToSearchFor).then(response => {
              const users = response.payload;
              for (const u of users) {
                u.isEditing = false;
                u.isEditingEndDate = false;
                if (u.effectiveTo !== null) {
                  // Only show the date part of the effectiveTo
                  u.effectiveTo = u.effectiveTo.split("T")[0];
                }
              }
              resolve(users);
            }, ({ errorCode, errorMessage }) => {
              reject({ errorCode, errorMessage });
            });
          } else {
            resolve([]);
          }
        }),
        individualAccount: this.modelFor('accounts.account').account.accountType === 'INDIVIDUAL',
        businessAccount: this.modelFor('accounts.account').account.accountType === 'BUSINESS'
      });
    },

    setupController(controller, model) {
      this._super(controller, model);
      console.log("printing out the model", model.invites);
      controller.set('addUserID', null);
      controller.set('addUserDurationAllowed', null);
      controller.set('deleteUserID', null);
      controller.set('inviteUserEmail', null);
      controller.set('deleteInviteUserEmail', null);
      controller.set('deleteSecondaryUserID', null);
      controller.set('primaryUserID', model.primary[0].userId);

      if (model.individualAccount) {
        new Ember.RSVP.Promise((resolve, reject) => {
          this.get('platform').doGet('account/sharing/' + model.primary[0].userId).then(response => {
            console.log("printing response in controller", response);
            controller.set('invites', response.invites);
            resolve(response.invites);
          }, ({ errorCode, errorMessage }) => {
            reject({ errorCode, errorMessage });
          });
        });
      }

      console.log("printing out the controller invites ", this.controller.get('invites'));
    },

    actions: {
      selectUserAccount(accountId, userId) {
        console.log("Selected user :" + userId + ", in account: " + accountId);
        this.controller.set('activeId', userId);
        this.transitionTo('users.user', userId);
      },

      selectUserInvite(inviteeEmail) {
        console.log("in selectUserInvite invitee email is :", inviteeEmail);
        this.controller.set('inviteeActiveId', inviteeEmail);
      },

      addUserToBusinessAccount() {
        const accountId = this.modelFor('accounts.account').account.accountId;

        const userId = this.controller.get('addUserID');
        if (!userId || parseInt(userId) < 1) {
          this.get('notify').error('Invalid user ID.');
          return;
        }

        let durationAllowed = -1;
        if (this.controller.get('addUserDurationAllowed')) {
          if (parseInt(this.controller.get('addUserDurationAllowed')) < 1) {
            this.get('notify').error('Minutes allowed must be greater than 0.');
            return;
          }

          // Convert minutes to seconds
          durationAllowed = parseInt(this.controller.get('addUserDurationAllowed')) * 60;
        }

        const endpoint = `mgmt/account/${accountId}/user/${userId}/business?durationAllowed=${durationAllowed}`;

        this.get('platform').doPost(endpoint).then(() => {
          this.get('notify').success(`Added user with ID ${userId} to account ${accountId}.`);
          this.refresh();
        }, ({ errorMessage }) => {
          this.get('notify').error(errorMessage);
        });
      },

      deleteUserFromBusinessAccount() {
        let accountId = this.modelFor('accounts.account').account.accountId;
        let userId = this.controller.get("deleteUserID");
        if (userId) {
          this.get('platform').doDelete('mgmt/account/' + accountId + '/user/' + userId + '/business').then(response => {
            this.get('notify').success('Delete user with ID ' + userId + ' from account ' + accountId);
            this.refresh();
          }, ({ errorCode, errorMessage }) => {
            this.get('notify').error(errorMessage);
          });
        } else {
          this.get('notify').error("Please input a valid number to delete a business user ");
        }
      },

      inviteSecondaryUser() {
        let primaryID = this.controller.get("primaryUserID");
        let inviteeEmail = this.controller.get("inviteUserEmail");
        if (inviteeEmail && primaryID) {
          this.get('platform').doPost('account/sharing/invite', {
            userId: primaryID,
            invitee: inviteeEmail
          }).then(response => {
            this.get('notify').success('Invited user with email ' + inviteeEmail + ' to account ');
            this.refresh();
          }, ({ errorCode, errorMessage }) => {
            this.get('notify').error(errorMessage);
          });
        } else {
          this.get('notify').error("Primary User ID or Invitee Email is invalid, please try again");
        }
        console.log("Inviting secondary users ", inviteeEmail);
      },
      deleteInviteSecondaryUser() {
        let inviteeEmail = this.controller.get("deleteInviteUserEmail");
        let primaryID = this.controller.get("primaryUserID");
        console.log(primaryID);
        console.log(inviteeEmail);

        if (inviteeEmail && primaryID) {
          this.get('platform').doDelete('account/sharing/invite', {
            userId: primaryID,
            invitee: inviteeEmail
          }).then(response => {
            this.get('notify').success('Deleted user with email ' + inviteeEmail + ' from account ');
            this.refresh();
          }, ({ errorCode, errorMessage }) => {
            this.get('notify').error(errorMessage);
          });
        } else {
          this.get('notify').error("Primary User ID or Invitee Email is invalid, please try again");
        }
        console.log("Deleting invitation of secondary users ", inviteeEmail);
      },

      deleteSecondaryUser() {
        let userID = this.controller.get("deleteSecondaryUserID");

        if (userID) {
          this.get('platform').doDelete('account/sharing/' + userID).then(response => {
            this.get('notify').success('Deleted user with email ' + userID + ' from account ');
            this.refresh();
          }, ({ errorCode, errorMessage }) => {
            this.get('notify').error(errorMessage);
          });
        } else {
          this.get('notify').error("UserID is not valid, please try again");
        }

        console.log("Deleting secondary users ", userID);
      }
    }
  });
});