define('aira-mgmt/components/select-single/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['form-group'],

		/* The label value */
		label: null,

		/* All possible options as array of {id, text} */
		options: null,

		/* Current selection */
		selectedOptionId: null,

		/* Handler when selection added receiving (id) */
		onSelect: null,

		didInsertElement() {
			this.$('select').on('change', () => {
				this.get('onSelect')(this.$('select').val());
			});
		},

		willDestroyElement() {
			this.$('select').off('change');
		}
	});
});