define('aira-mgmt/serializers/service', ['exports', 'aira-mgmt/serializers/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({

		normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
			let response = [];
			let requests = payload.requests;

			requests.forEach(raw => {
				let request = {
					id: raw.serviceid,
					agentLogin: raw.agentname,
					start: raw.startTimeStamp,
					end: raw.endTimeStamp,
					request: raw.requestTimeStamp,
					type: raw.requestType,
					status: raw.status,
					durationMinutes: raw.durationMinutes,
					requestSource: raw.requestSource,
					testCall: raw.testCall
				};

				response.push(request);
			});

			console.log(response);

			return this._super(store, primaryModelClass, response, id, requestType);
		}
	});
});