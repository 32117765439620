define('aira-mgmt/components/questions/question-text/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    q: null,

    htmlId: null,

    value: null,

    update: () => {},

    init() {
      this._super(...arguments);
      let q = this.get('q');

      this.set('htmlId', 'q-' + q.property.key);

      if (q.values.length > 0) {
        this.set('value', q.values[0].value);
      }
    },

    actions: {
      change(value) {
        this.update(this.get('q'), value);
      },

      update(q, value) {
        this.update(q, value);
      }
    }
  });
});