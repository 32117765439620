define('aira-mgmt/accounts/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['type', 'q'],

    // Binds search input to query param
    q: null,
    type: "",
    search: null,
    searchType: "",

    propertyOptions: [{ text: 'Individual Account', id: 'INDIVIDUAL' }, { text: 'Business Account', id: 'BUSINESS' }, { text: 'Reseller Account', id: 'RESELLER' }],

    actions: {

      selectAccountType(accountType) {
        this.set("accountType", accountType);
        console.log(this.get("accountType"));
      }

    }
  });
});