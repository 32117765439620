define('aira-mgmt/adapters/wifi', ['exports', 'aira-mgmt/adapters/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({

		query(store, type, query) {
			let url = [this.host, this.namespace, 'user', 'wifiprofile'].join('/');
			return this.ajax(url, 'GET', { data: { userId: query.filter.userId } });
		},

		queryRecord(store, type, query) {
			throw 'cannot query single wifi profile';
		},

		createRecord(store, type, snapshot) {
			let url = [this.host, this.namespace, 'user', 'wifiprofile'].join('/');
			let data = this.serialize(snapshot, { includeId: true });
			return this.ajax(url, 'POST', { data: data });
		},

		updateRecord(store, type, snapshot) {
			let url = [this.host, this.namespace, 'user', 'wifiprofile'].join('/');
			let data = this.serialize(snapshot, { includeId: true });
			return this.ajax(url, 'PUT', { data: data });
		},

		deleteRecord(store, type, snapshot) {
			let url = [this.host, this.namespace, 'user', 'wifiprofile'].join('/');
			url = [url, '?userId=', this._encodeUrl(snapshot.record.get('userId')), '&id=', snapshot.id].join('');
			return this.ajax(url, 'DELETE');
		}
	});
});