define('aira-mgmt/users/user/wifi/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),
    wifi: Ember.computed.alias('state.users.user.wifi'),

    notify: Ember.inject.service(),

    model(p) {
      // Ugly, but currently cannot query wifi profiles by id.
      // This find the correct wifi profile in the array of profiles in our parent route's model
      return this.modelFor('users.user.wifi').profiles.findBy('id', p.wifi_id);
    },

    setupController(controller, model) {
      this._super(controller, model);
      // applies row highlight on transition from row click
      this.set('wifi.selectedWifiProfileId', model.id);
    },

    actions: {
      willTransition: function (transition) {
        if (this.get('state.isDirty') && !confirm('Are you sure? You have unsaved changes.')) {
          transition.abort();
        } else {
          this.set('wifi.selectedWifiProfileId', -1);
        }
      },

      save(profile, wifiProps) {
        profile.setProperties(wifiProps);
        return profile.save().then(() => {
          this.set('state.isDirty', false);
          this.get('notify').success('Wifi profile was updated.');
          this.transitionTo('users.user.wifi');
        });
      },

      cancel(profile) {
        profile.rollbackAttributes();
        this.transitionTo('users.user.wifi');
      },

      delete(profile) {
        profile.destroyRecord().then(() => {
          this.set('state.isDirty', false);
          this.get('notify').success('Wifi profile was deleted.');
          this.transitionTo('users.user.wifi');
        });
      }
    }
  });
});