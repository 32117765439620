define('aira-mgmt/accounts/account/sites/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['sitePg', 'siteType', 'siteQuery'],

    activeId: null,

    // Binds search input to query param
    sitePg: 0,
    siteType: null,
    siteQuery: "",
    search: null,
    searchType: "",

    siteDisableBack: null,
    siteDisableNext: null,

    isGeneratingCSV: false,
    currentExportPage: 1,

    actions: {

      sitePreviousPage() {
        this.decrementProperty('sitePg');
      },

      siteNextPage() {
        this.incrementProperty('sitePg');
      }
    }
  });
});