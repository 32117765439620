define('aira-mgmt/adapters/user', ['exports', 'aira-mgmt/adapters/application'], function (exports, _application) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = _application.default.extend({

				platform: Ember.inject.service(),

				query(store, type, query) {
						let wrappedQuery = query.filter.search.trim();
						let searchType = query.filter.type;
						let url = `search?e=U&type=${searchType}&q=${wrappedQuery}`;
						return this.platform.doGet(url);
				},

				queryRecord(store, type, query) {
						let url = `user?login=${query.filter.login}`;
						return this.platform.doGet(url);
				}
		});
});