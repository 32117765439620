define('aira-mgmt/mixins/model-editor', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	let _dataKey = null;
	let _modelKey = null;
	let _propNames = null;

	/**
  * Enables dirty checking for the route that this is mixed into.
  * Relies on state.isDirty flag.
  * Route should inspect this flag in willTransition() and reset it to false on successful save.
  */
	exports.default = Ember.Mixin.create({

		state: Ember.inject.service(),

		_bindModelEditor(datakey, modelKey, propNames) {
			_dataKey = datakey;
			_modelKey = modelKey;
			_propNames = propNames;
		},

		didReceiveAttrs() {
			this._super(...arguments);
			this.set(_dataKey, Ember.Object.create(this.get(_modelKey).getProperties(_propNames)));
			_propNames.forEach(name => this.get(_dataKey).addObserver(name, this, this._modelEditorOnChange));
		},

		_modelEditorOnChange: function (data, key) {
			let model = this.get(_modelKey);
			let newValue = data.get(key);
			let oldValue = model.get(key);

			if (newValue === '') {
				newValue = null;
			}

			if (newValue !== oldValue) {
				this.set('state.isDirty', true);
			} else {
				let dirty = false;
				_propNames.forEach(name => {
					dirty = model.get(name) !== data.get(name);
				});

				this.set('state.isDirty', dirty);
			}
		}
	});
});