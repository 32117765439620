define('aira-mgmt/accounts/account/sites/new/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    displayable: true,

    searchable: true,

    requireAgentApproval: true,

    enabled: true,

    gpsActivated: true,

    bufferAroundFence: true,

    errorMessage: null

  });
});