define('aira-mgmt/accounts/account/users/controller', ['exports', 'luxon'], function (exports, _luxon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    platform: Ember.inject.service(),

    addUserID: null,
    addUserDurationAllowed: null,
    deleteUserID: null,
    invites: null,
    primaryUserID: null,
    inviteUserEmail: null,
    deleteInviteUserEmail: null,
    deleteSecondaryUserID: null,
    editASL: false,
    addingUser: false,

    actions: {
      noop() {},
      editDurationAllowed(user) {
        for (const u of this.model.users) {
          // Set isEditing to true for the selected user and false for everyone else
          if (u.id === user.id) {
            Ember.set(u, 'editDurationAllowed', u.durationAllowed === -1 ? null : Math.ceil(u.durationAllowed / 60));
            Ember.set(u, 'isEditing', true);
          } else {
            Ember.set(u, 'isEditing', false);
          }
        }
      },
      cancelEditDurationAllowed(user) {
        Ember.set(user, 'isEditing', false);
      },
      saveDurationAllowed(user) {
        let durationAllowed = -1;
        if (user.editDurationAllowed) {
          if (parseInt(user.editDurationAllowed) < 1) {
            this.notify.error('Minutes allowed must be greater than 0.');
            return;
          }

          // Convert minutes to seconds
          durationAllowed = parseInt(user.editDurationAllowed) * 60;
        }

        if (durationAllowed === user.durationAllowed) {
          // Nothing changed
          Ember.set(user, 'isEditing', false);
          return;
        }

        this.platform.doPut(`mgmt/account/${user.accountId}/user/${user.userId}/business`, {
          durationAllowed: durationAllowed
        }).then(() => {
          this.notify.success(`Updated minutes allowed for user with ID ${user.userId} in account ${user.accountId}.`);
          Ember.set(user, 'durationAllowed', durationAllowed);
          Ember.set(user, 'isEditing', false);
        }, ({ errorMessage }) => {
          this.notify.error(errorMessage);
        });
      },
      editEndDate(user) {
        for (const u of this.model.users) {
          // Set isEditingEndDate to true for the selected user and false for everyone else
          if (u.id === user.id) {
            Ember.set(u, 'editEndDate', u.effectiveTo ? u.effectiveTo.split('T')[0] : null);
            Ember.set(u, 'isEditingEndDate', true);
          } else {
            Ember.set(u, 'isEditingEndDate', false);
          }
        }
      },
      cancelEndDate(user) {
        Ember.set(user, 'isEditingEndDate', false);
      },
      saveEndDate(user) {
        if (user.editEndDate) {
          if (new Date(user.editEndDate) < new Date()) {
            this.notify.error('Date should be greater than current date.');
            return;
          }
        }
        const effectiveTo = _luxon.DateTime.fromISO(user.editEndDate, { zone: 'America/Los_Angeles' }).setZone('UTC').toISO({ suppressMilliseconds: true });

        this.platform.doPut(`mgmt/account/${user.accountId}/user/${user.userId}/business/service-end-date`, {
          effectiveTo
        }).then(() => {
          this.notify.success(`Updated the end date for user with ID ${user.userId} in account ${user.accountId}.`);
          Ember.set(user, 'effectiveTo', user.editEndDate);
          Ember.set(user, 'isEditingEndDate', false);
        }, ({ errorMessage }) => {
          this.notify.error(errorMessage);
        });
      },
      removeEndDate(user) {
        this.platform.doPut(`mgmt/account/${user.accountId}/user/${user.userId}/business/service-end-date`, {
          effectiveTo: null
        }).then(() => {
          this.notify.success(`Removed the end date for user with ID ${user.userId} in account ${user.accountId}.`);
          Ember.set(user, 'effectiveTo', null);
          Ember.set(user, 'isEditingEndDate', false);
        }, ({ errorMessage }) => {
          this.notify.error(errorMessage);
        });
      },
      editASL(user) {
        this.model.users.forEach(u => {
          Ember.set(u, 'isEditingASL', u.id === user.id);
        });
        Ember.set(this, 'editASL', !user.asl);
      },
      saveASL(user) {
        this.platform.doPut(`mgmt/account/${user.accountId}/user/${user.userId}/business/asl`, {
          value: this.editASL
        }).then(() => {
          this.notify.success(`Updated ASL for user with ID ${user.userId} in account ${user.accountId}.`);
          Ember.set(user, 'asl', this.editASL);
          Ember.set(user, 'isEditingASL', false);
        }, ({ errorMessage }) => {
          this.notify.error(errorMessage);
        });
      },
      cancelASL(user) {
        Ember.set(user, 'isEditingASL', false);
      }
    }
  });
});