define('aira-mgmt/helpers/is-emergency-contact', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isEmergencyContact = isEmergencyContact;
  function isEmergencyContact([contact] /*, hash*/) {
    let type = contact.get('type');
    return type === 'Emergency' || type === 'emergency';
  }

  exports.default = Ember.Helper.helper(isEmergencyContact);
});