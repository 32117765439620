define('aira-mgmt/accounts/account/sites/new/route', ['exports', 'aira-mgmt/accounts/account/route', 'aira-mgmt/utils/validate-dates', 'aira-mgmt/helpers/change-PST-to-ISO'], function (exports, _route, _validateDates, _changePSTToISO) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    notify: Ember.inject.service(),
    platform: Ember.inject.service(),

    setupController(controller, model) {
      this._super(controller, model);
      this.set('controller.attributesLocalized', (0, _route.addAllLangLocalizations)());
    },

    actions: {

      cancelNewSite() {
        let accountId = this.modelFor('accounts.account').account.accountId;
        this.transitionTo('accounts.account.sites', accountId);
      },

      createSite() {
        let accountId = this.modelFor('accounts.account').account.accountId;
        // check site information
        let dataIsGood = true;
        let errorMessage = [];
        if (this.get('controller.agentMessage') === '' || this.get('controller.agentMessage') == null) {
          dataIsGood = false;
          errorMessage.push('Missing agent message');
        }

        const effectiveFromError = (0, _validateDates.validateDate)(this.get('controller.effectiveFrom'), 'effective from', true);
        if (effectiveFromError) {
          dataIsGood = false;
          errorMessage.push(effectiveFromError);
        }

        const effectiveToValue = this.get('controller.effectiveTo');
        if (effectiveToValue !== '' && effectiveToValue != null) {
          const effectiveToError = (0, _validateDates.validateDate)(effectiveToValue, 'effective to', false);
          if (effectiveToError) {
            dataIsGood = false;
            errorMessage.push(effectiveToError);
          }
        }

        if (this.get('controller.address1') === '' || this.get('controller.address1') === undefined) {
          dataIsGood = false;
          errorMessage.push('Missing address line 1');
        }
        if (this.get('controller.city') === '' || this.get('controller.city') === undefined) {
          dataIsGood = false;
          errorMessage.push('Missing city');
        }
        if (this.get('controller.country') === '' || this.get('controller.country') === undefined) {
          dataIsGood = false;
          errorMessage.push('Missing country');
        }
        if (this.get('controller.siteDurationPerCallCheckbox')) {
          if (this.get('controller.durationPerCall') === '' || this.get('controller.durationPerCall') === undefined) {
            dataIsGood = false;
            errorMessage.push('Missing enforced duration per call');
          } else {
            const value = parseInt(this.get('controller.durationPerCall'));
            if (value <= 0 || isNaN(value)) {
              dataIsGood = false;
              errorMessage.push('Duration per call value must be a valid number greater than 0');
            }
          }
        }

        const validationArrayRsp = (0, _route.validateAttributesLocalized)(this.get('controller.attributesLocalized'));
        if (!validationArrayRsp[0]) {
          dataIsGood = false;
          errorMessage.push(...validationArrayRsp[1]);
        }

        if (dataIsGood) {
          const accessibilityValue = [{
            id: 2,
            type: 'Deaf or hard of hearing',
            value: this.get('controller.isASLSite') ? 'true' : 'false'
          }];

          this.get('platform').doPost(['access', 'site'].join('/'), {
            account: {
              id: accountId
            },
            accountId: accountId,
            class: 'site',
            type: "public",
            termsAndConditions: this.get('controller.termsAndConditions') ? this.get('controller.termsAndConditions') : null,
            agentMessage: this.get('controller.agentMessage'),
            effectiveFrom: (0, _changePSTToISO.changePSTtoISO)([this.get('controller.effectiveFrom')]),
            effectiveTo: this.get('controller.effectiveTo') ? (0, _changePSTToISO.changePSTtoISO)([this.get('controller.effectiveTo')]) : null,
            siteAddress: {
              accountId: accountId,
              address1: this.get('controller.address1'),
              address2: this.get('controller.address2') ? this.get('controller.address2') : null,
              city: this.get('controller.city'),
              state: this.get('controller.state'),
              country: this.get('controller.country'),
              zip: this.get('controller.zip'),
              addressType: 'site'
            },
            fence: this.get('controller.fence') ? JSON.parse(this.get('controller.fence')) : null,
            durationPerCall: this.get('controller.siteDurationPerCallCheckbox') ? parseInt(this.get('controller.durationPerCall')) : -1,
            enabled: this.get('controller.enabled'),
            requireAgentApproval: this.get('controller.requireAgentApproval'),
            searchable: this.get('controller.searchable'),
            displayable: this.get('controller.displayable'),
            gpsActivated: this.get('controller.gpsActivated'),
            bufferAroundFence: this.get('controller.bufferAroundFence'),
            accessibility: accessibilityValue,
            attributesLocalized: (0, _route.removeNotSetLocalizations)(this.get('controller.attributesLocalized'))
          }).then(() => {
            this.get('notify').success('Site created!');
            this.transitionTo('accounts.account.sites', accountId);
          }, error => {
            this.send('error', error.errorMessage);
          });
          this.set('controller.errorMessage', null);
        } else {
          this.set('controller.errorMessage', errorMessage);
        }
      }
    }
  });
});