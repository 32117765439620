define('aira-mgmt/users/user/access/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    notify: Ember.inject.service(),

    platform: Ember.inject.service(),

    model() {
      return this.modelFor('users.user.access');
    },

    setupController: function (controller, model) {
      this._super(controller, model);

      if (this.get('controller.model.access').durationAllowed === -1) {
        this.set('controller.accessLimitDuration', false);
      } else {
        this.set('controller.accessLimitDuration', true);
      }
      if (this.get('controller.model.access').durationPerCall === -1) {
        this.set('controller.accessLimitDurationPerCall', false);
      } else {
        this.set('controller.accessLimitDurationPerCall', true);
      }
      if (this.get('controller.model.access').callsPerPeriod === -1) {
        this.set('controller.accessLimitCallFrequency', false);
      } else {
        this.set('controller.accessLimitCallFrequency', true);
      }
    },

    actions: {
      saveAccess: function () {
        let dataIsGood = true;
        const errorMessage = [];
        if (this.get('controller.accessLimitDuration')) {
          if (this.get('controller.model.access').durationAllowed === '' || this.get('controller.model.access').durationAllowed === undefined) {
            dataIsGood = false;
            errorMessage.push('Must fill "Maximum Duration Allowed" when "Enforce Maximum Duration Allowed" checkbox checked');
          }
          if (this.get('controller.model.access').durationAllowed < 0 && this.get('controller.model.access').durationAllowed !== -1) {
            dataIsGood = false;
            errorMessage.push('"Maximum Duration Allowed" must be greater than 0.');
          }
        } else {
          this.set('controller.model.access.durationAllowed', -1);
        }
        if (this.get('controller.accessLimitDurationPerCall')) {
          if (this.get('controller.model.access').durationPerCall === '' || this.get('controller.model.access').durationPerCall === undefined) {
            dataIsGood = false;
            errorMessage.push('Must fill "Maximum Call Duration" when "Enforce Maximum Call Duration" checkbox checked');
          }
          if (this.get('controller.model.access').durationPerCall < 0 && this.get('controller.model.access').durationPerCall !== -1) {
            dataIsGood = false;
            errorMessage.push('"Maximum Duration Per Call" must be greater than 0.');
          }
        } else {
          this.set('controller.model.access.durationPerCall', -1);
        }
        if (this.get('controller.accessLimitCallFrequency')) {
          if (this.get('controller.model.access').callsPerPeriod === '' || this.get('controller.model.access').callsPerPeriod === undefined) {
            dataIsGood = false;
            errorMessage.push('Must fill "Calls Per Period" when "Enforce Call Frequency" checkbox checked');
          }
          if (this.get('controller.model.access').callsPerPeriod < 0 && this.get('controller.model.access').callsPerPeriod !== -1) {
            dataIsGood = false;
            errorMessage.push('"Calls Per Period" must be greater than 0.');
          }
          if (this.get('controller.model.access').callPeriodLength === '' || this.get('controller.model.access').callPeriodLength === undefined) {
            dataIsGood = false;
            errorMessage.push('Must fill "Call Period Length" when "Enforce Call Frequency" checkbox checked');
          }
          if (this.get('controller.model.access').callPeriodLength < 0) {
            dataIsGood = false;
            errorMessage.push('"Call Period Length" must be greater than 0.');
          }
        } else {
          this.set('controller.model.access.callsPerPeriod', -1);
          this.set('controller.model.access.callPeriodLength', 0);
        }
        if (this.get('controller.accessRenewalEnabled')) {
          if (this.get('controller.model.access').accessRenewalCron === '' || this.get('controller.model.access').accessRenewalCron === undefined || this.get('controller.model.access').accessRenewalCron === null) {
            dataIsGood = false;
            errorMessage.push('Must fill "Access Renewal Cron" when "Enforce Access Renewal" checkbox checked');
          }
        }

        if (dataIsGood) {
          const userId = this.get('controller.model').access.userId;
          const newAccess = {
            durationAllowed: this.get('controller.model.access').durationAllowed === -1 ? -1 : parseInt(this.get('controller.model.access').durationAllowed),
            durationPerCall: this.get('controller.model.access').durationPerCall === -1 ? -1 : parseInt(this.get('controller.model.access').durationPerCall),
            callsPerPeriod: this.get('controller.model.access').callsPerPeriod === -1 ? -1 : parseInt(this.get('controller.model.access').callsPerPeriod),
            callPeriodLength: this.get('controller.model.access').callPeriodLength === 0 ? 0 : parseInt(this.get('controller.model.access').callPeriodLength),
            accessRenewalEnabled: this.get('controller.model.access').accessRenewalEnabled,
            accessRenewalCron: this.get('controller.model.access').accessRenewalCron,
            accessRenewalCronType: 'QUARTZ'
          };
          if (newAccess) {
            this.get('platform').doPut(['mgmt', userId, 'access', 'default'].join('/'), newAccess).then(() => {
              this.get('notify').success('Access updated!');
              this.transitionTo('users.user.access', userId);
            }, error => {
              this.send('error', error.errorMessage);
            });
          }
        } else {
          this.set('controller.errorMessage', errorMessage);
        }
      },
      cancelEditAccess() {
        this.transitionTo('users.user.access');
      }
    }
  });
});