define('aira-mgmt/users/user/support/route', ['exports', 'aira-mgmt/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),

    session: Ember.inject.service(),

    notify: Ember.inject.service(),

    support: Ember.computed.alias('state.users.user.support'),

    mqtt: null,

    userId: null,

    apiKey: null,

    serial: null,

    selectedProfile: null,

    beforeModel() {
      let self = this;

      console.log(this.get('session.data.authenticated.id'));
      let clientId = "mgmt-" + this.get('session.data.authenticated.id');
      let mqttHost = _environment.default.APP.mqtt_host;
      let port = Number(_environment.default.APP.mqtt_port);
      let mqttPath = _environment.default.APP.mqtt_path;
      self.apiKey = _environment.default.APP.X_API_Key;
      self.userId = self.get('session.data.authenticated.id');
      this.set('mqtt', new Paho.MQTT.Client(mqttHost, port, mqttPath, clientId));

      this.mqtt.onMessageArrived = function (message) {
        self.onMessageArrived.call(self, message);
      };
      this.mqtt.onConnectionLost = function () {
        self.onConnectionLost.call(self);
      };
    },

    model() {
      let userId = this.modelFor('users.user').get('id');

      return Ember.RSVP.hash({
        settings: this.get('store').findRecord('setting', userId),
        profiles: this.get('store').query('wifi', { filter: { userId: userId } })
      });
    },

    setupController(controller, model) {
      this._super(controller, model);
      // Provide state service to generated controller, avoids making our own.
      controller.set('state', this.get('state'));

      this.set('controller.smartappStatus', 'offline');
      this.set('controller.glassStatus', 'offline');
      this.set('controller.getWifi', '');
      this.set('controller.status', '');
      this.set('controller.profileOptions', model.profiles.map(item => {
        return {
          id: item.get('id'),
          text: item.get('ssid')
        };
      }));
    },

    onConnected() {
      console.log('onConnected');
      let self = this;
      this.userId = this.modelFor('users.user').get('id');
      this.serial = this.currentModel.settings.get('glassPublicSerial');

      Ember.RSVP.all([new Ember.RSVP.Promise((resolve, reject) => {
        this.mqtt.subscribe(_environment.default.APP.mqtt_env + '/sys/glass/session/' + this.userId, {
          qos: 0,
          onSuccess: resolve,
          onFailure: reject
        });
      }), new Ember.RSVP.Promise((resolve, reject) => {
        this.mqtt.subscribe(_environment.default.APP.mqtt_env + '/sys/smartapp/+/session/' + this.userId, {
          qos: 0,
          onSuccess: resolve,
          onFailure: reject
        });
      }), new Ember.RSVP.Promise((resolve, reject) => {
        this.mqtt.subscribe(_environment.default.APP.mqtt_env + '/si/sg/' + this.userId, {
          qos: 0,
          onSuccess: resolve,
          onFailure: reject
        });
      }), new Ember.RSVP.Promise((resolve, reject) => {
        this.mqtt.subscribe(_environment.default.APP.mqtt_env + '/si/gs/' + this.userId, {
          qos: 0,
          onSuccess: resolve,
          onFailure: reject
        });
      }), new Ember.RSVP.Promise((resolve, reject) => {
        this.mqtt.subscribe('devices/' + this.serial + '/+', { qos: 0, onSuccess: resolve, onFailure: reject });
      })]).then(() => {
        self.onSubscribed.call(self);
      });
    },

    onSubscribed() {
      console.log('onSubscribed');

      this.set('support.mqttConnected', true);
      this.set('controller.mqttConnecting', false);

      this.sendTelemetryPing();
    },

    onConnectionLost() {
      this.set('support.mqttConnected', false);
      alert('connection to mqtt lost');
    },

    onMessageArrived(message) {
      console.log('IN', message.destinationName, ":", message.payloadString);
      let topic = message.destinationName;

      if (topic.startsWith(_environment.default.APP.mqtt_env + '/sys/glass/')) {
        this.onGlassSessionMessage(message.payloadString);
      } else if (topic.startsWith(_environment.default.APP.mqtt_env + '/sys/smartapp/')) {
        this.onSmartappSessionMessage(message.payloadString);
      } else if (topic.startsWith(_environment.default.APP.mqtt_env + '/si/sg/')) {
        this.onSmartappToGlassMessage(message.payloadString);
      } else if (topic.startsWith(_environment.default.APP.mqtt_env + '/si/gs/')) {
        this.onGlassToSmartappMessage(message.payloadString);
      } else if (/devices\/\w+\/accepted/.test(topic)) {
        this.onDeviceAcceptedMessage(message.payloadString);
      } else if (/devices\/\w+\/rejected/.test(topic)) {
        this.onDeviceRejectedMessage(message.payloadString);
      }
    },

    onGlassSessionMessage(payload) {
      let obj = JSON.parse(payload);
      if (obj.type === 'online') {
        this.set('controller.glassStatus', 'online');
      } else {
        this.set('controller.glassStatus', 'offline');
      }
    },

    onSmartappSessionMessage(payload) {
      let obj = JSON.parse(payload);
      if (obj.type === 'online') {
        this.set('controller.smartappStatus', 'online');
      } else {
        this.set('controller.smartappStatus', 'offline');
      }
    },

    onSmartappToGlassMessage(payload) {
      let splits = payload.split('###');
      if (splits[0] === 'STS') {}
    },

    onGlassToSmartappMessage(payload) {
      let splits = payload.split('###');
      if (splits[0] === 'ACK' && splits[1] === 'STS') {
        this.set('controller.glassStatus', 'online');
        this.set('controller.status', payload);
      }
    },

    sendTelemetryPing() {
      this.sendTelemetryMessage({ req: 'ping' });
    },

    sendTelemetryGetWifi() {
      this.sendTelemetryMessage({ req: 'getWifi' });
    },

    sendTelemetryRestart() {
      this.sendTelemetryMessage({ req: 'restart' });
    },

    sendTelemetryMessage(payload) {
      this.sendMessage('devices/' + this.serial, JSON.stringify(payload));
    },

    onDeviceAcceptedMessage(payload) {
      let obj = JSON.parse(payload);
      if (obj.res === 'pong') {
        this.set('controller.telemetryStatus', 'online');
      } else if (obj.res === 'getWifi') {
        this.set('controller.getWifi', obj.ssid);
      } else if (obj.res === 'restart') {
        this.get('notify').success('Restarting Aira');
      }
    },

    onDeviceRejectedMessage(payload) {
      let obj = JSON.parse(payload);
      alert(obj.error);
    },

    sendMessage(topic, payload) {
      console.log('OUT', topic, ":", payload);
      let message = new Paho.MQTT.Message(payload);
      message.destinationName = topic;
      this.mqtt.send(message);
    },

    actions: {
      willTransition() {
        if (this.get('support.mqttConnected')) {
          this.set('support.mqttConnected', false);

          // Avoid the alert when leaving the support tab.
          this.mqtt.onConnectionLost = function () {};

          try {
            this.mqtt.disconnect();
          } catch (e) {
            // already disconnected
          }
        }

        return true; // allow bubbling
      },

      connectMqtt() {
        this.set('controller.mqttConnecting', true);
        let self = this;
        this.mqtt.connect({
          timeout: 30,
          useSSL: true,
          cleanSession: true,
          userName: 'Key-' + self.apiKey, // TODO use proper token based auth
          password: self.userId.toString(),
          onSuccess() {
            self.onConnected.call(self);
          },
          onFailure() {
            self.onConnectionLost.call(self);
          }
        });
      },

      getWifi() {
        this.sendTelemetryGetWifi();
      },

      restart() {
        this.sendTelemetryRestart();
      },

      testCall() {
        if (this.modelFor('users.user').get('status') === 'Test Mode') {
          this.sendMessage(_environment.default.APP.mqtt_env + '/si/sg/' + this.userId, 'CAA###');
        } else {
          alert('Please enable the test mode status first.');
        }
      },

      status() {
        this.sendMessage(_environment.default.APP.mqtt_env + '/si/sg/' + this.userId, 'STS###');
      },

      selectWifi(id) {
        this.selectedProfile = this.currentModel.profiles.findBy('id', id);
      },

      switchWifi() {
        if (this.selectedProfile != null) {
          this.sendMessage(_environment.default.APP.mqtt_env + '/si/sg/' + this.userId, 'SHS###' + JSON.stringify({
            ssid: this.selectedProfile.get('ssid'),
            securityType: this.selectedProfile.get('securityType'),
            secretkey: this.selectedProfile.get('secretkey'),
            type: this.selectedProfile.get('type')
          }));
        } else {
          alert('You must select wifi');
        }
      }
    }
  });
});