define('aira-mgmt/onboard/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

		/*beforeModel() {
   	this._super(...arguments);
   	// Must call super for AuthenticatedRouteMixin
   },*/

		model() {
			return this.get('store').createRecord('user', {
				login: null,
				firstName: null,
				lastName: null,
				password: null,
				status: 'Active',
				gender: null,
				phoneNumber: null,
				appRoles: [{ appName: "AGENT DASHBOARD", roles: ["AIRA AGENT"] }],
				language: ['English']
			});
		},

		setupController(controller, model) {
			this._super(controller, model);
			controller.set('title', 'New User');
			controller.set('subtitle', 'Basic Info');
		},

		deactivate() {
			// Not done in cancel incase user navigates away by another means
			this.get('currentModel').rollbackAttributes();
		},

		actions: {
			next() {
				console.log('NEXT');
			},

			updateTitle(title) {
				this.set('controller.title', title);
			},

			updateSubtitle(subtitle) {
				this.set('controller.subtitle', subtitle);
			},

			cancel() {
				this.transitionTo('users');
			}
		}
	});
});