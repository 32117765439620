define('aira-mgmt/components/user-answer/component', ['exports', 'aira-mgmt/mixins/model-editor'], function (exports, _modelEditor) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	let answerPropNames = ['qcode', 'questionText', 'answerText'];

	exports.default = Ember.Component.extend(_modelEditor.default, {

		state: Ember.inject.service(),

		title: null,

		address: null,

		onSave: null,

		onCancel: null,

		onDelete: null,

		data: null,

		init() {
			this._super(...arguments);
			this._bindModelEditor('data', 'answer', answerPropNames);
		},

		actions: {
			save() {
				const answerProps = this.get('data').getProperties(answerPropNames);
				this.get('onSave')(this.get('answer'), answerProps);
			},

			cancel() {
				this.set('state.isDirty', false);
				this.get('onCancel')(this.get('answer'));
			},

			delete() {
				if (confirm('Are you sure?')) {
					this.get('onDelete')(this.get('answer'));
				}
			}
		}
	});
});