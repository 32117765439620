define("aira-mgmt/helpers/equal", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.equal = equal;
  function equal([leftSide, rightSide] /*, hash*/) {
    return leftSide === rightSide;
  }

  exports.default = Ember.Helper.helper(equal);
});