define('aira-mgmt/accounts/account/sites/site/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    geojsonUrl: null,

    timezone: null

  });
});