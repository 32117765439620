define('aira-mgmt/users/user/properties/property/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),
    notify: Ember.inject.service(),
    platform: Ember.inject.service(),

    // SAVE HELPER FUNCTIONS
    getChildrenData(data, parentKey) {
      // console.log('getChildrenData~BEGIN', data, parentKey);
      let childrenData = {};

      Object.keys(data).forEach(key => {
        if (key.startsWith(parentKey) && key !== parentKey) {
          childrenData[key] = data[key];
        }
      });

      // console.log('getChildrenData~END', childrenData);
      return childrenData;
    },

    getChildrenPayload(data, depth) {
      // console.log('getChildrenPayload~BEGIN', data, depth);

      let childrenPayload = {};

      Object.keys(data).forEach(key => {
        if (key.split('.').length === depth) {
          let childrenValue = {};
          childrenValue['value'] = data[key];
          childrenPayload[key] = [];

          let moreChildrenData = this.getChildrenData(data, key);

          if (!Ember.isEmpty(moreChildrenData)) {
            let moreChildrenPayload = this.getChildrenPayload(moreChildrenData, depth + 1);
            childrenValue['children'] = moreChildrenPayload;
          }

          childrenPayload[key].push(childrenValue);
        }
      });

      // console.log('getChildrenPayload~END', childrenPayload);
      return childrenPayload;
    },

    setupController(controller, model) {
      this._super(controller, model);
      controller.resetPendingUpdates();
    },

    actions: {
      willTransition: function (transition) {
        if (this.get('state.isDirty') && !confirm('Are you sure? You have unsaved changes.')) {
          transition.abort();
        }
      },

      save(data) {
        this.set('controller.inProgress', true);
        // console.log(this.refresh());

        // console.log('save DATA', data);

        let payload = {};
        let parentKey = this.modelFor('users.user.properties.property').key;

        payload['value'] = data[parentKey];
        let childrenData = this.getChildrenData(data, parentKey);

        if (!Ember.isEmpty(childrenData)) {
          let childrenPayload = this.getChildrenPayload(childrenData, 2);
          payload['children'] = childrenPayload;
        }

        // console.log('payload', payload);

        this.get('platform').doPost('user/' + this.modelFor('users.user').id + '/property/' + parentKey + '/value', payload).then(() => {
          this.get('notify').success('New Property Value Successfully Added!');
          this.get('controller').resetPendingUpdates();
          this.transitionTo('users.user.properties');
        }, error => {
          this.send('error', error);
        }).finally(() => {
          this.set('controller.inProgress', false);
        });
      },

      cancel() {
        this.transitionTo('users.user.properties.property');
      }
    }
  });
});