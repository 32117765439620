define('aira-mgmt/onboard/wifi/new/route', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({

		state: Ember.inject.service(),

		notify: Ember.inject.service(),

		model() {
			const login = this.modelFor('onboard.wifi').user.get('login');

			return this.get('store').createRecord('wifi', {
				username: login,
				priority: null,
				ssid: null,
				securityType: null,
				secretkey: null,
				type: null
			});
		},

		setupController(controller, model) {
			this._super(controller, model);
			this.send('toggleNext', false);
		},

		deactivate() {
			// Not done in cancel incase user navigates away by another means
			this.get('currentModel').rollbackAttributes();
			this.send('toggleNext', true);
		},

		actions: {
			willTransition: function (transition) {
				if (this.get('state.isDirty') && !confirm('Are you sure? You have unsaved changes.')) {
					transition.abort();
				}
			},

			save(profile, wifiProps) {
				profile.setProperties(wifiProps);
				profile.save().then(() => {
					this.set('state.isDirty', false);
					this.send('newWifiProfile', this.get('currentModel'));
					this.get('notify').success('Wifi profile was added.');
					this.transitionTo('onboard.wifi');
				});
			},

			cancel() {
				this.transitionTo('onboard.wifi');
			}
		}
	});
});