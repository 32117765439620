define('aira-mgmt/routes/login', ['exports', 'aira-mgmt/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    firebase: Ember.inject.service(),
    session: Ember.inject.service(),

    setupController: function (controller, model) {
      this._super(controller, model);

      if (_environment.default.environment !== 'development') {
        // Handle the sign-in with redirect result.
        this.set('controller.isLoading', true);
        this.get('session').authenticate('authenticator:firebase').then(() => this.transitionTo('users')).catch(() => {}) // This is expected when we're not authenticated. Ignore.
        .finally(() => this.set('controller.isLoading', false));
      }
    },

    actions: {
      login() {
        if (_environment.default.environment !== 'development') {
          // Sign-in with redirect.
          this.get('firebase').login();
        } else {
          // Sign-in with popup.
          this.set('controller.isLoading', true);
          this.get('session').authenticate('authenticator:firebase').then(() => this.transitionTo('users')).catch(() => {}) // This is expected when we're not authenticated. Ignore.
          .finally(() => this.set('controller.isLoading', false));
        }
      }
    }
  });
});