define('aira-mgmt/accounts/account/sites/site/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    platform: Ember.inject.service(),

    model(p) {
      return Ember.RSVP.hash({
        site: new Ember.RSVP.Promise((resolve, reject) => {
          this.get('platform').doGet('access/site/' + p.site_id).then(response => {
            resolve(response);
          }, ({ errorCode, errorMessage }) => {
            reject({ errorCode, errorMessage });
          });
        })
      });
    }
  });
});