define('aira-mgmt/users/user/access/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    platform: Ember.inject.service(),

    notify: Ember.inject.service(),

    model() {
      return Ember.RSVP.hash({
        access: new Ember.RSVP.Promise((resolve, reject) => {
          this.get('platform').doGet(`mgmt/${this.modelFor('users.user').id}/access/default`).then(response => {
            response.userId = parseInt(this.modelFor('users.user').id);
            console.log('response data with userID', response);
            resolve(response);
          }, ({ errorCode, errorMessage }) => {
            reject({ errorCode, errorMessage });
            return [];
          });
        })
      });
    }
  });
});