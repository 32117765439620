define('aira-mgmt/helpers/duration-allowed', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.durationAllowed = durationAllowed;
  function durationAllowed([access]) {
    if (access.userType !== 'business') {
      return '';
    } else if (access.durationAllowed === -1) {
      return 'Unlimited';
    }
    return (0, _emberInflector.pluralize)(Math.ceil(access.durationAllowed / 60), 'minute');
  }

  exports.default = Ember.Helper.helper(durationAllowed);
});