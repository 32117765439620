define('aira-mgmt/users/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

    queryParams: {
      q: {
        refreshModel: true
      },
      type: {
        refreshModel: true
      }
    },

    state: Ember.inject.service(),

    notify: Ember.inject.service(),

    platform: Ember.inject.service(),

    model(params) {
      if (params.q && params.type) {
        return Ember.RSVP.hash({
          user: new Ember.RSVP.Promise((resolve, reject) => {
            this.get('platform').doGet('search/user?type=' + encodeURIComponent(params.type) + '&q=' + encodeURIComponent(params.q)).then(response => {
              resolve(response);
            }, ({ errorCode, errorMessage }) => {
              reject({ errorCode, errorMessage });
              return [];
            });
          })
        });
      } else {
        return [];
      }
    },

    afterModel(model) {
      if (model.user) {
        this.set('state.ariaLive', model.user.result.length + ' results.');
      }
    },

    setupController(controller, model) {
      this._super(controller, model);
      controller.set('users', this.get('users'));
      controller.set('search', controller.get('q'));
      controller.set('propertyType', "");
      if (controller.get('q') !== null && model.length < 1) {
        this.get('notify').error('404 Error: No User was found');
      }
    },

    actions: {
      loading(transition) {
        let controller = this.get('controller');
        if (controller) {
          controller.set('isSearching', true);
          transition.promise.finally(function () {
            controller.set('isSearching', false);
          });
        } else {}
      },

      /**
       * searchByName: This method gets called by a button click or pressing enter when searching for a name
       */
      searchByName() {
        //creates first and last name
        let firstName = "?";
        let lastName = "?";
        const searchType = "NAME";

        //Checks if the first name search bar has a non-empty value
        if (this.get("controller.firstN")) {
          firstName = this.get("controller.firstN");
          //if it has a valid value reassign firstName variable
        }

        //Checks if the last name search bar has a non-empty value
        if (this.get("controller.lastN")) {
          lastName = this.get("controller.lastN");
          //if it has a valid value reassign lastName variable
        }
        //Actually what does this do? Do I need it?
        this.controller.set('activeId', -1);

        //search is the query that will get passed to platform
        //if either firstName or lastName didn't have a valid search parameter, then it will be sent as a ?
        const search = firstName + "/" + lastName;
        //transition with the query

        this.transitionTo('users', {
          queryParams: {
            q: search,
            type: searchType
          }
        });
      },

      /**
       * searchByAddress: This method gets called by a button click or pressing enter when searching for by address
       */
      searchByAddress() {
        //Assigns all the search parameter to ?
        let city = "?";
        let state = "?";
        let country = "?";
        let zip = "?";
        const searchType = "ADDRESS";

        //Checks each search bar, for city, state, country and zip, checks if they have non empty values, if they do
        //assign the variables above to them, if not they stay as ?
        if (this.get("controller.city")) {
          city = this.get("controller.city");
        }
        if (this.get("controller.state")) {
          state = this.get("controller.state");
        }
        if (this.get("controller.country")) {
          country = this.get("controller.country");
        }
        if (this.get("controller.zip")) {
          zip = this.get("controller.zip");
        }

        //Creating the search query that will be sent to platform
        const search = city + "/" + state + "/" + country + "/" + zip;

        this.transitionTo('users', {
          queryParams: {
            q: search,
            type: searchType
          }
        });
      },
      /**
       * searchByEmail: This method gets called by a button click or pressing enter when searching for by Email
       */
      searchByEmail() {

        //assigns email variable to ?
        let email = "?";
        const searchType = "EMAIL";

        //checks if the phone search bar is non empty, if it is copy it's value into phone
        if (this.get("controller.email") !== "") {
          email = this.get("controller.email");
        }

        //creates the search query passed to platform
        const search = email;

        //transition with query
        this.transitionTo('users', {
          queryParams: {
            q: search,
            type: searchType
          }
        });
      },

      /**
       * searchByPhone: This method gets called by a button click or pressing enter when searching for by Phone
       */

      searchByPhone() {
        //assigns the phone variable to ?
        let phone = "?";
        const searchType = "PHONE";

        //checks if the phone search bar is non empty, if it is copy it's value into phone
        if (this.get("controller.phone") !== "") {
          phone = this.get("controller.phone");
        }

        //create a search query the value in the search, or ? otherwise
        const search = phone;

        this.transitionTo('users', {
          queryParams: {
            q: search,
            type: searchType

          }
        });
      },

      /**
       * searchByUserId: This method gets called by a button click or pressing enter when searching by User Id
       */
      searchByUserId() {
        //sets the intial value to ?
        let userId = "?";
        const searchType = "USER_ID";

        //checks if the value in the user is non empty
        if (this.get("controller.userId") !== "") {
          userId = this.get("controller.userId");
        }

        const search = userId;
        this.transitionTo('users', {
          queryParams: {
            q: search,
            type: searchType
          }
        });
      },

      searchByUserProperty() {
        const search = this.get("controller.propertyType");
        const searchType = "PROPERTY";
        this.transitionTo('users', {
          queryParams: {
            q: search,
            type: searchType
          }
        });
      },

      select(userId) {
        this.controller.set('activeId', userId);
        try {
          let activeTab = this.controllerFor('users.user').get('activeTab');
          // Don't pass whole model because it's currently incomplete.
          // This will trigger a call to the api that fetches the whole model
          this.transitionTo('users.user.' + activeTab, userId);
        } catch (e) {
          // Just means no user had been selected yet so there's
          // no controller initialized so there's no activeTab to maintain.
          // Don't pass whole model because it's currently incomplete.
          // This will trigger a call to the api that fetches the whole model
          this.transitionTo('users.user', userId);
        }
      },

      /**
       *
       * @param {String} searchType
       * This method takes a string when it's called by one of the toolbar buttons
       * That String will denote which search bars we want to display
       */
      displaySearch(searchType) {

        const nameToIdMap = new Map([["Name", "searchNames"], ["Address", "searchAddress"], ["Email", "searchByEmail"], ["Phone", "searchByPhone"], ["UserId", "searchByUserId"], ["Property", "searchByUserProperty"]]);

        const setOfSearchDisplays = new Set();
        setOfSearchDisplays.add(document.getElementById("searchNames"));
        setOfSearchDisplays.add(document.getElementById("searchAddress"));
        setOfSearchDisplays.add(document.getElementById("searchByEmail"));
        setOfSearchDisplays.add(document.getElementById("searchByPhone"));
        setOfSearchDisplays.add(document.getElementById("searchByUserId"));
        setOfSearchDisplays.add(document.getElementById("searchByUserProperty"));

        for (let searchDisplay of setOfSearchDisplays) {
          if (nameToIdMap.get(searchType) === searchDisplay.id) {
            searchDisplay.style.display = "block";
          } else {
            searchDisplay.style.display = "none";
          }
        }
      }

    }

  });
});