define('aira-mgmt/accounts/account/products/product/edit/route', ['exports', 'aira-mgmt/utils/validate-dates', 'aira-mgmt/helpers/change-PST-to-ISO', 'aira-mgmt/helpers/change-ISO-to-PST', 'aira-mgmt/accounts/account/route'], function (exports, _validateDates, _changePSTToISO, _changeISOToPST, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    platform: Ember.inject.service(),
    notify: Ember.inject.service(),
    product: null,

    model(selection) {
      const accountId = this.modelFor('accounts.account').account.accountId;
      return Ember.RSVP.hash({
        id: parseInt(selection.product_id),
        currentProductPromotion: null,
        isProductOrPromotion: null,
        product: new Ember.RSVP.Promise((resolve, reject) => {
          this.get('platform').doGet('mgmt/account/' + accountId + '/product').then(response => {
            resolve(response);
          }, ({ errorCode, errorMessage }) => {
            reject({ errorCode, errorMessage });
          });
        }),
        promotion: new Ember.RSVP.Promise((resolve, reject) => {
          this.get('platform').doGet('mgmt/account/' + accountId + '/promotion').then(response => {
            resolve(response);
          }, ({ errorCode, errorMessage }) => {
            reject({ errorCode, errorMessage });
          });
        })
      });
    },

    afterModel(model) {
      // first try to find the product/promotion from the product model
      const productFound = model.product.payload.find(product => {
        return product.id === model.id;
      });

      if (productFound != null) {
        model.currentProductPromotion = productFound;
        console.log('MODEL #:', model.currentProductPromotion.id);
        model.isProductOrPromotion = 'product';
        return;
      }

      // then try to find the product/promotion from the promotion model
      const promotionFound = model.promotion.payload.find(promotion => {
        return promotion.id === model.id;
      });

      if (promotionFound != null) {
        model.currentProductPromotion = promotionFound;
        model.isProductOrPromotion = 'promotion';
        return;
      }

      console.warn('product promotion not found');
    },

    setupController(controller, model) {
      this._super(controller, model);
      this.set('controller.id', model.currentProductPromotion.id);
      this.set('controller.type', model.currentProductPromotion.type);
      this.set('controller.editClass', model.isProductOrPromotion);
      this.set('controller.editKey', model.currentProductPromotion.key);
      this.set('controller.editEnabled', model.currentProductPromotion.enabled);
      this.set('controller.editTermsAndConditions', model.currentProductPromotion.termsAndConditions);
      this.set('controller.editTermsAndConditionsUrl', model.currentProductPromotion.termsAndConditionsUrl);
      this.set('controller.editAgentMessage', model.currentProductPromotion.agentMessage);
      this.set('controller.editEffectiveFrom', (0, _changeISOToPST.changeISOtoPST)([model.currentProductPromotion.effectiveFrom]));
      this.set('controller.editEffectiveTo', (0, _changeISOToPST.changeISOtoPST)([model.currentProductPromotion.effectiveTo]));
      this.set('controller.editEnforcedOnExplorers', model.currentProductPromotion.enforcedOnExplorers);

      this.set('controller.editLimitDurationPerCallIsDisabled', true);
      this.set('controller.editLimitCallFrequencyIsDisabled', true);

      if (model.currentProductPromotion.durationPerCall !== -1) {
        this.set('controller.editLimitDurationPerCall', true);
        this.set('controller.editCallLimitCheckbox', true);
        this.set('controller.editLimitDurationPerCallIsDisabled', false);
        this.set('controller.editDurationPerCall', model.currentProductPromotion.durationPerCall);
      } else {
        this.set('controller.editLimitDurationPerCall', false);
        this.set('controller.editCallLimitCheckbox', false);
        this.set('controller.editDurationPerCall', null);
      }

      this.set('controller.editRequireAgentApproval', model.currentProductPromotion.requireAgentApproval);
      this.set('controller.editVisible', model.currentProductPromotion.visible);
      this.set('controller.editSticky', model.currentProductPromotion.sticky);
      this.set('controller.editEntireCall', model.currentProductPromotion.entireCall);
      this.set('controller.editAvailableToGuests', model.currentProductPromotion.availableToGuests);

      if (model.currentProductPromotion.callsPerPeriod !== -1) {
        this.set('controller.editLimitCallFrequency', true);
        this.set('controller.editLimitCallFrequencyIsDisabled', false);
        this.set('controller.editCallFrequencyCheckbox', true);
        this.set('controller.editCallsPerPeriod', model.currentProductPromotion.callsPerPeriod);
        this.set('controller.editCallPeriodLength', model.currentProductPromotion.callPeriodLength);
        this.set('controller.editEnforcedOnDuration', model.currentProductPromotion.enforcedOnDuration);
      } else {
        this.set('controller.editLimitCallFrequency', false);
        this.set('controller.editCallFrequencyCheckbox', false);
        this.set('controller.editCallsPerPeriod', null);
        this.set('controller.editCallPeriodLength', null);
        this.set('controller.editEnforcedOnDuration', null);
      }

      if (model.currentProductPromotion.durationAllowed !== -1) {
        this.set('controller.editDurationAllowed', model.currentProductPromotion.durationAllowed);
      } else {
        this.set('controller.editDurationAllowed', null);
      }
      this.set('controller.attributesLocalized', (0, _route.addMissingLangLocalizations)(model.currentProductPromotion.attributesLocalized));
    },

    actions: {
      editCancel() {
        this.transitionTo('accounts.account.products.index');
      },

      editSave() {

        let accountId = this.modelFor('accounts.account').account.accountId;
        console.log('ADD PRESSED', accountId);

        // check product name
        let dataIsGood = true;
        let errorMessage = [];
        if (this.get('controller.editKey') === '' || this.get('controller.editKey') === null) {
          dataIsGood = false;
          errorMessage.push('Missing product key');
        }
        if (this.get('controller.editAgentMessage') === '' || this.get('controller.editAgentMessage') === null) {
          dataIsGood = false;
          errorMessage.push('Missing agent message');
        }

        const effectiveFromError = (0, _validateDates.validateDate)(this.get('controller.editEffectiveFrom'), 'effective from', false);
        if (effectiveFromError) {
          dataIsGood = false;
          errorMessage.push(effectiveFromError);
        }

        const effectiveToValue = this.get('controller.editEffectiveTo', false);
        if (effectiveToValue !== '' && effectiveToValue != null) {
          const effectiveToError = (0, _validateDates.validateDate)(effectiveToValue, 'effective to', false);
          if (effectiveToError) {
            dataIsGood = false;
            errorMessage.push(effectiveToError);
          }
        }
        if (this.get('controller.editDurationAllowed') && parseInt(this.get('controller.editDurationAllowed')) <= 0) {
          dataIsGood = false;
          errorMessage.push('Duration per month value must be a valid number greater than 0');
        }
        if (this.get('controller.editCallLimitCheckbox')) {
          if (this.get('controller.editDurationPerCall') === '' || this.get('controller.editDurationPerCall') === null) {
            dataIsGood = false;
            errorMessage.push('Missing enforced duration per call');
          } else {
            const value = parseInt(this.get('controller.editDurationPerCall'));
            if (value <= 0 || isNaN(value)) {
              dataIsGood = false;
              errorMessage.push('Duration per call value must be a valid number greater than 0');
            }
          }
        }
        if (this.get('controller.editCallFrequencyCheckbox')) {
          if (this.get('controller.editCallsPerPeriod') === '' || this.get('controller.editCallsPerPeriod') === null) {
            dataIsGood = false;
            errorMessage.push('Missing enforced calls per period');
          } else {
            const value = parseInt(this.get('controller.editCallsPerPeriod'));
            if (value <= 0 || isNaN(value)) {
              dataIsGood = false;
              errorMessage.push('Calls per period value must be a valid number greater than 0');
            }
          }
          if (this.get('controller.editCallPeriodLength') === '' || this.get('controller.editCallPeriodLength') === null) {
            dataIsGood = false;
            errorMessage.push('Missing enforced call period length');
          } else {
            const value = parseInt(this.get('controller.editCallPeriodLength'));
            if (value <= 0 || isNaN(value)) {
              dataIsGood = false;
              errorMessage.push('Call period length value must be a valid number greater than 0');
            }
          }
          if (this.get('controller.editEnforcedOnDuration') === '' || this.get('controller.editEnforcedOnDuration') === null) {
            dataIsGood = false;
            errorMessage.push('Missing enforced on duration');
          } else {
            const value = parseInt(this.get('controller.editEnforcedOnDuration'));
            if (value <= 0 || isNaN(value)) {
              dataIsGood = false;
              errorMessage.push('Enforced on duration value must be a valid number greater than 0');
            }
          }
        }

        const validationArrayRsp = (0, _route.validateAttributesLocalized)(this.get('controller.attributesLocalized'));
        if (!validationArrayRsp[0]) {
          dataIsGood = false;
          errorMessage.push(...validationArrayRsp[1]);
        }

        if (dataIsGood) {
          this.get('platform').doPut(['access', this.get('controller.editClass'), this.get('controller.id')].join('/'), {
            class: this.get('controller.editClass'),
            entireCall: this.get('controller.editEntireCall'),
            termsAndConditionsUrl: this.get('controller.editTermsAndConditionsUrl'),
            type: this.get('controller.type') || 'PUBLIC',
            enabled: this.get('controller.editEnabled'),
            availableToGuests: this.get('controller.editAvailableToGuests'),
            enforcedOnExplorers: this.get('controller.editEnforcedOnExplorers'),
            termsAndConditions: this.get('controller.editTermsAndConditions'),
            effectiveTo: this.get('controller.editEffectiveTo') ? (0, _changePSTToISO.changePSTtoISO)([this.get('controller.editEffectiveTo')]) : null,
            agentMessage: this.get('controller.editAgentMessage'),
            visible: this.get('controller.editVisible'),
            requireAgentApproval: this.get('controller.editRequireAgentApproval'),
            callPeriodLength: this.get('controller.editCallFrequencyCheckbox') ? parseInt(this.get('controller.editCallPeriodLength')) : 3600,
            enforcedOnDuration: this.get('controller.editCallFrequencyCheckbox') ? parseInt(this.get('controller.editEnforcedOnDuration')) : 60,
            callsPerPeriod: this.get('controller.editCallFrequencyCheckbox') ? parseInt(this.get('controller.editCallsPerPeriod')) : -1,
            sticky: this.get('controller.editSticky'),
            durationPerCall: this.get('controller.editCallLimitCheckbox') ? parseInt(this.get('controller.editDurationPerCall')) : -1,
            effectiveFrom: this.get('controller.editEffectiveFrom') ? (0, _changePSTToISO.changePSTtoISO)([this.get('controller.editEffectiveFrom')]) : null,
            durationAllowed: this.get('controller.editDurationAllowed') ? parseInt(this.get('controller.editDurationAllowed')) : -1,
            account: {
              id: accountId
            },
            attributesLocalized: (0, _route.removeNotSetLocalizations)(this.get('controller.attributesLocalized'))
          }).then(() => {
            console.log('HTTP PUT was successful');
            this.get('notify').success('Access updated!');
            this.transitionTo('accounts.account.products.index');
          }, error => {
            console.error('HTTP PUT errored out');
            this.send('error', error.errorMessage);
          });
          this.set('controller.editErrorMessage', null);
        } else {
          this.set('controller.editErrorMessage', errorMessage);
        }
      }
    }
  });
});