define('aira-mgmt/components/account-profile/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		state: Ember.inject.service(),

		platform: Ember.inject.service(),

		notify: Ember.inject.service(),

		profile: null,
		actions: {
			selectAccessAI(allowAccessAI) {
				this.set('profile.accessAi', allowAccessAI);
			},
			save() {
				let updatedProfile = this.get('profile');
				this.get('platform').doPut(['mgmt', 'account', 'update'].join('/'), {
					accountId: updatedProfile.accountId,
					businessType: updatedProfile.businessType,
					name: updatedProfile.name,
					acceptBusinessUsers: updatedProfile.acceptBusinessUsers,
					allowRecording: updatedProfile.allowRecording,
					accessAi: updatedProfile.accessAi
				}).then(() => {
					this.get('notify').success('Account profile was updated!');
				}, error => {
					this.send('error', error);
				});
			}
		},
		accessAIOptions: [{ text: 'Full Access', id: 'FULL_ACCESS' }, { text: 'No History', id: 'NO_HISTORY' }, { text: 'Block Access', id: 'NO_ACCESS' }]

	});
});