define('aira-mgmt/users/user/wifi/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),

    model() {
      const userId = this.modelFor('users.user').get('id');

      return Ember.RSVP.hash({
        user: this.get('store').findRecord('user', userId),
        profiles: this.get('store').query('wifi', { filter: { userId: userId } })
      });
    },

    setupController(controller, model) {
      this._super(controller, model);
      // Provide state service to generated controller, avoids making our own.
      controller.set('state', this.get('state'));
    },

    actions: {
      // received from wifi/new route. Need to reload all profiles because API
      // does not return the id of the newly created profile.
      newWifiProfile() {
        this.get('currentModel.profiles').update();
      },

      selectWifiProfile(profile) {
        this.transitionTo('users.user.wifi.edit', profile);
      }
    }
  });
});