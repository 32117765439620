define('aira-mgmt/serializers/setting', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.JSONSerializer.extend({

		normalizeUpdateRecordResponse(store, primaryModelClass, payload, id, requestType) {
			payload.id = id;
			return { data: payload };
		}
	});
});