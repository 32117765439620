define("aira-mgmt/accounts/account/products/product/new/controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    class: null,
    classes: [{ text: "product", id: "product" }, { text: "promotion", id: "promotion" }],
    key: null,
    enabled: true,
    termsAndConditions: null,
    termsAndConditionsUrl: null,
    agentMessage: null,
    effectiveFrom: null,
    effectiveTo: null,
    durationAllowed: null,
    durationPerCall: null,
    callPeriodLength: null,
    callsPerPeriod: null,
    enforcedOnDuration: null,
    requireAgentApproval: false,
    visible: true,
    sticky: false,
    entireCall: true,
    availableToGuests: true,
    enforcedOnExplorers: false,
    limitDurationPerCall: false,
    callLimitCheckbox: false,
    limitCallFrequency: false,
    callFrequencyCheckbox: false,
    errorMessage: null,
    attributesLocalized: null,

    actions: {
      callLimit(ele) {
        let enabled = document.getElementById("durationPerCallEnabled");
        if (ele.target.checked) {
          enabled.disabled = false;
        } else {
          enabled.disabled = true;
        }
        this.callLimitCheckbox = ele.target.checked;
      },

      frequencyLimit(ele) {
        let callsPerPeriod = document.getElementById("callsPerPeriodEnabled");
        let callPeriodLength = document.getElementById("callPeriodLengthEnabled");
        let enforcedDuration = document.getElementById("enforcedDurationEnabled");
        let enforcedOnExplorers = document.getElementById("enforcedOnExplorers");
        if (ele.target.checked) {
          callsPerPeriod.disabled = false;
          callPeriodLength.disabled = false;
          enforcedDuration.disabled = false;
          enforcedOnExplorers.disabled = false;
        } else {
          callsPerPeriod.disabled = true;
          callPeriodLength.disabled = true;
          enforcedDuration.disabled = true;
          enforcedOnExplorers.disabled = true;
        }
        this.callFrequencyCheckbox = ele.target.checked;
      },

      productPromotionSelection(selected) {
        this.set('class', selected);
        console.log('SELECTION:', this.get('class'));
      }
    }
  });
});