define('aira-mgmt/accounts/account/sites/index/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

    queryParams: {
      sitePg: {
        refreshModel: true
      },
      siteType: {
        refreshModel: true
      },
      siteQuery: {
        refreshModel: true
      }
    },

    state: Ember.inject.service(),

    notify: Ember.inject.service(),

    platform: Ember.inject.service(),

    model(params) {
      let accountId = this.modelFor('accounts.account').account.accountId;
      let pg = params.sitePg == null ? 0 : params.sitePg;

      if (params.siteType && params.siteQuery) {
        return Ember.RSVP.hash({
          site: new Ember.RSVP.Promise((resolve, reject) => {
            this.get('platform').doGet('search/account/' + accountId + '/site?type=' + encodeURIComponent(params.siteType) + '&q=' + encodeURIComponent(params.siteQuery) + '&page=' + pg + '&size=20').then(response => {
              console.log('MODEL SITE DATA:', response);
              resolve(response);
            }, ({ errorCode, errorMessage }) => {
              reject({ errorCode, errorMessage });
              return [];
            });
          })
        });
      } else {
        return Ember.RSVP.hash({
          site: new Ember.RSVP.Promise((resolve, reject) => {
            this.get('platform').doGet('mgmt/account/' + accountId + '/site?page=' + pg + "&size=20").then(response => {
              console.log('MODEL ALL DATA:', response);
              resolve(response);
            }, ({ errorCode, errorMessage }) => {
              reject({ errorCode, errorMessage });
            });
          })
        });
      }
    },

    afterModel(model) {
      if (model.site) {
        this.set('state.ariaLive', model.site.length + ' site results.');
      }
    },

    setupController(controller, model) {
      this._super(controller, model);
      if (model.site.response.hasMore) {
        this.set('controller.siteDisableNext', false);
      } else {
        this.set('controller.siteDisableNext', true);
      }

      if (this.get('controller.sitePg') > 0) {
        this.set('controller.siteDisableBack', false);
      } else {
        this.set('controller.siteDisableBack', true);
      }
    },

    actions: {

      selectSite(accountId, site) {
        console.log(`Selected site: ${site.id} in account: ${accountId}`);
        this.controller.set('activeId', site.id);
        this.transitionTo('accounts.account.sites.site.index', accountId, site.id);
      },

      createNewSite() {
        let accountId = this.modelFor('accounts.account').account.accountId;
        this.transitionTo('accounts.account.sites.new', accountId);
      },

      /** Search box actions starts here*/

      loading(transition) {
        let controller = this.get('controller');
        if (controller) {
          controller.set('isSearching', true);
          transition.promise.finally(function () {
            controller.set('isSearching', false);
          });
        }
      },

      /**
       * searchBySiteId: This method gets called by a button click or pressing enter when searching by Site Id
       */
      searchBySiteId() {
        let siteId = "";
        const searchType = "SITE_ID";

        //checks if the value in the user is non empty
        if (this.get("controller.siteId") !== "") {
          siteId = this.get("controller.siteId");
        }
        const search = siteId;
        this.transitionTo({
          queryParams: {
            sitePg: 0,
            siteQuery: search,
            siteType: searchType
          }
        });
      },

      /**
       * searchBySiteName: This method gets called by a button click or pressing enter when searching for a site name
       */
      searchBySiteName() {
        //creates site name
        let siteName = "";
        const searchType = "NAME";

        //Checks if the name search bar has a non-empty value
        if (this.get("controller.siteName")) {
          siteName = this.get("controller.siteName");
          //if it has a valid value reassign siteName variable
        }

        const search = siteName;
        //transition with the query

        this.transitionTo({
          queryParams: {
            sitePg: 0,
            siteQuery: search,
            siteType: searchType
          }
        });
      },

      /**
       * searchBySiteAddress: This method gets called by a button click or pressing enter when searching for by address
       */
      searchBySiteAddress() {
        //Assigns all the search parameter to ?
        let address1 = "?";
        let city = "?";
        let state = "?";
        let country = "?";
        let zip = "?";
        const searchType = "ADDRESS";

        //Checks each search bar for, address1, city, state, country and zip, checks if they have non empty values, if they do
        //assign the variables above to them, if not they stay as ?
        if (this.get("controller.siteAddress1")) {
          address1 = this.get("controller.siteAddress1");
        }
        if (this.get("controller.siteCity")) {
          city = this.get("controller.siteCity");
        }
        if (this.get("controller.siteState")) {
          state = this.get("controller.siteState");
        }
        if (this.get("controller.siteCountry")) {
          country = this.get("controller.siteCountry");
        }
        if (this.get("controller.siteZip")) {
          zip = this.get("controller.siteZip");
        }

        //Creating the search query that will be sent to platform
        const search = [address1, city, state, country, zip].join('/');

        this.transitionTo({
          queryParams: {
            sitePg: 0,
            siteQuery: search,
            siteType: searchType
          }
        });
      },

      /**
       *
       * @param {String} searchType
       * This method takes a string when it's called by one of the toolbar buttons
       * That String will denote which search bars we want to display
       */
      siteDisplaySearch(searchType) {

        const nameToIdMap = new Map([["SiteId", "searchSiteId"], ["SiteName", "searchSiteName"], ["SiteAddress", "searchSiteAddress"]]);

        const setOfSearchDisplays = new Set();
        setOfSearchDisplays.add(document.getElementById("searchSiteId"));
        setOfSearchDisplays.add(document.getElementById("searchSiteName"));
        setOfSearchDisplays.add(document.getElementById("searchSiteAddress"));

        for (let searchDisplay of setOfSearchDisplays) {
          if (nameToIdMap.get(searchType) === searchDisplay.id) {
            searchDisplay.style.display = "block";
          } else {
            searchDisplay.style.display = "none";
          }
        }
      },

      async exportSiteList() {
        if (this.controller.get('isGeneratingCSV')) {
          return; // Prevent multiple simultaneous exports
        }

        this.controller.set('isGeneratingCSV', true);
        let accountId = this.modelFor('accounts.account').account.accountId;
        let allData = [];
        let currentPage = 0;

        // Function to format address
        const formatAddress = siteAddress => {
          if (!siteAddress) return '';

          const mainAddress = siteAddress.address2 && siteAddress.address2.length ? `${siteAddress.address1} ${siteAddress.address2}` : siteAddress.address1;

          return `${mainAddress}, ${siteAddress.city}, ${siteAddress.state}, ${siteAddress.country}, ${siteAddress.zip}`;
        };

        // Function to transform site data
        const transformSiteData = site => {
          return {
            id: site.id,
            name: site.name || '',
            partnerReferenceId: site.partnerReferenceId || '',
            siteAddress: formatAddress(site.siteAddress)
          };
        };

        // Function to create and download CSV
        const downloadCSV = data => {
          try {
            const headers = ['ID', 'Name', 'Partner reference ID', 'Site Address'].join(',');
            const csvData = data.map(item => {
              return [item.id, `"${(item.name || '').replace(/"/g, '""')}"`, `"${(item.partnerReferenceId || '').replace(/"/g, '""')}"`, `"${(item.siteAddress || '').replace(/"/g, '""')}"`].join(',');
            });

            const csvContent = [headers, ...csvData].join('\n');

            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `account_${accountId}_sites_export_${new Date().toISOString().split('T')[0]}.csv`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url); // Clean up the URL object
          } catch (error) {
            console.error('Error generating CSV:', error);
            throw error;
          }
        };

        // Function to fetch a single page
        const fetchPage = async page => {
          try {
            this.controller.set('currentExportPage', page + 1);
            const response = await this.get('platform').doGet(`mgmt/account/${accountId}/site?page=${page}&size=20`);

            if (response && response.payload && response.payload.length > 0) {
              // Transform each site before adding to allData
              const transformedSites = response.payload.map(transformSiteData);
              allData = [...allData, ...transformedSites];

              // If we have more pages, recursively fetch next page
              if (response.response.hasMore) {
                return await fetchPage(page + 1);
              }
            }
            return allData;
          } catch (error) {
            console.error(`Error fetching page ${page}:`, error);
            throw error;
          }
        };

        try {
          const completeData = await fetchPage(currentPage);

          if (completeData && completeData.length > 0) {
            downloadCSV(completeData);
            this.get('notify').success(`Export completed successfully with ${completeData.length} sites`);
          } else {
            this.send('error', 'No data to export');
          }
        } catch (error) {
          console.error('Error exporting data:', error);
          this.send('error', `Failed to export data, ${error.message}`);
        } finally {
          this.controller.set('isGeneratingCSV', false);
          this.controller.set('currentExportPage', 1);
        }
      }
    }
  });
});