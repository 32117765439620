define("aira-mgmt/helpers/has-role", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.hasRole = hasRole;
	function hasRole([appName, roleName, appRoles]) {
		for (let i = 0, il = appRoles.length; i < il; i++) {
			if (appName === appRoles[i].appName) {
				for (let j = 0, jl = appRoles[i].roles.length; j < jl; j++) {
					if (roleName === appRoles[i].roles[j]) {
						return true;
					}
				}
			}
		}

		return false;
	}

	exports.default = Ember.Helper.helper(hasRole);
});