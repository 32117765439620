define('aira-mgmt/users/user/questions/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),
    questions: Ember.computed.alias('state.users.user.questions'),

    notify: Ember.inject.service(),

    model(p) {
      return this.get('store').findRecord('answer', p.question_id);
    },

    setupController(controller, model) {
      this._super(controller, model);
      // applies row highlight on transition from row click
      this.set('questions.selectedAnswerId', model.id);
    },

    actions: {
      willTransition: function (transition) {
        if (this.get('state.isDirty') && !confirm('Are you sure? You have unsaved changes.')) {
          transition.abort();
        } else {
          this.set('questions.selectedAnswerId', -1);
        }
      },

      save(answer, answerProps) {
        answer.setProperties(answerProps);
        return answer.save().then(() => {
          this.set('state.isDirty', false);
          this.get('notify').success('Response was updated.');
          this.transitionTo('users.user.questions');
        });
      },

      cancel(answer) {
        answer.rollbackAttributes();
        this.transitionTo('users.user.questions');
      },

      delete(answer) {
        answer.destroyRecord().then(() => {
          this.set('state.isDirty', false);
          this.get('notify').success('Response was deleted.');
          this.transitionTo('users.user.questions');
        });
      }
    }
  });
});