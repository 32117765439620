define('aira-mgmt/components/user-profile/component', ['exports', 'aira-mgmt/mixins/model-editor'], function (exports, _modelEditor) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var profilePropNames = ['id', 'status', 'firstName', 'lastName', 'phoneticFirstName', 'login', 'password', 'language', 'gender', 'dateOfBirth', 'phoneNumber', 'phoneVerified', 'properties', 'notes'];

	exports.default = Ember.Component.extend(_modelEditor.default, {

		state: Ember.inject.service(),

		profile: null,

		onSave: null,

		onCancel: null,

		onNext: null,

		autofocusFirstName: false,

		disableEmail: true,

		showPassword: false, // defaulted to false, because returned password is hashed

		data: null,

		notes: '',

		isAiShareAuthSubscribed: Ember.computed('buildAiProgramJoined', function () {
			var buildAiProgramJoined = this.get('profile').get('buildAiProgramJoined');
			if (buildAiProgramJoined === null) {
				return 'null';
			}
			return buildAiProgramJoined.toString();
		}),

		isShareSelectDisabled: Ember.computed('buildAiProgramJoined', function () {
			return this.get('profile.buildAiProgramJoined') !== true;
		}),

		init: function init() {
			this._super.apply(this, arguments);
			this._bindModelEditor('data', 'profile', profilePropNames);
			var languagesSelected = [];
			var _iteratorNormalCompletion = true;
			var _didIteratorError = false;
			var _iteratorError = undefined;

			try {
				for (var _iterator = this.get('profile').get('language')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
					var language = _step.value;
					var _iteratorNormalCompletion2 = true;
					var _didIteratorError2 = false;
					var _iteratorError2 = undefined;

					try {
						for (var _iterator2 = this.get("languageOptions")[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
							var languageOption = _step2.value;

							if (languageOption.id === language) {
								languagesSelected.push(languageOption);
							}
						}
					} catch (err) {
						_didIteratorError2 = true;
						_iteratorError2 = err;
					} finally {
						try {
							if (!_iteratorNormalCompletion2 && _iterator2.return) {
								_iterator2.return();
							}
						} finally {
							if (_didIteratorError2) {
								throw _iteratorError2;
							}
						}
					}
				}
			} catch (err) {
				_didIteratorError = true;
				_iteratorError = err;
			} finally {
				try {
					if (!_iteratorNormalCompletion && _iterator.return) {
						_iterator.return();
					}
				} finally {
					if (_didIteratorError) {
						throw _iteratorError;
					}
				}
			}

			this.set('languageSelected', languagesSelected);
			var props = this.get('data.properties');
			if (props && props.notes && props.notes.length > 0) {
				this.set('data.notes', props.notes[0].value);
			}
		},
		didUpdateAttrs: function didUpdateAttrs() {
			this._super.apply(this, arguments);
			var languagesSelected = [];
			var _iteratorNormalCompletion3 = true;
			var _didIteratorError3 = false;
			var _iteratorError3 = undefined;

			try {
				for (var _iterator3 = this.get('profile').get('language')[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
					var language = _step3.value;
					var _iteratorNormalCompletion4 = true;
					var _didIteratorError4 = false;
					var _iteratorError4 = undefined;

					try {
						for (var _iterator4 = this.get("languageOptions")[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
							var languageOption = _step4.value;

							if (languageOption.id === language) {
								languagesSelected.push(languageOption);
							}
						}
					} catch (err) {
						_didIteratorError4 = true;
						_iteratorError4 = err;
					} finally {
						try {
							if (!_iteratorNormalCompletion4 && _iterator4.return) {
								_iterator4.return();
							}
						} finally {
							if (_didIteratorError4) {
								throw _iteratorError4;
							}
						}
					}
				}
			} catch (err) {
				_didIteratorError3 = true;
				_iteratorError3 = err;
			} finally {
				try {
					if (!_iteratorNormalCompletion3 && _iterator3.return) {
						_iterator3.return();
					}
				} finally {
					if (_didIteratorError3) {
						throw _iteratorError3;
					}
				}
			}

			this.set('languageSelected', languagesSelected);
		},


		actions: {
			selectLanguage: function selectLanguage(languages) {
				this.set('data.language', languages.map(function (l) {
					return l.id;
				}));
				this.set('languageSelected', languages);
			},
			selectBuildAiProgramJoined: function selectBuildAiProgramJoined(buildAiProgramJoined) {
				var buildAiProgramJoinedProp = this.get('data').getProperties(['properties']);
				buildAiProgramJoinedProp.properties['buildAiProgramJoined'][0]['value'] = Boolean(buildAiProgramJoined === 'true');
				this.set('data.properties', buildAiProgramJoinedProp.properties);
			},
			selectStatus: function selectStatus(status) {
				this.set('data.status', status);
			},
			selectGender: function selectGender(gender) {
				this.set('data.gender', gender);
			},
			updateUserNotes: function updateUserNotes(editor) {
				// Get the HTML content directly from the editor
				var htmlContent = editor.getText().trim() ? editor.root.innerHTML : '';
				// Update both data.notes and properties.notes
				var notesProp = this.get('data').getProperties(['properties']);
				notesProp.properties['notes'][0]['value'] = htmlContent;
				this.set('data.properties', notesProp.properties);
			},
			save: function save() {
				// Ensure we're saving the latest notes value
				var profileProps = this.get('data').getProperties(profilePropNames);
				this.get('onSave')(this.get('profile'), profileProps);
			},
			next: function next() {
				var profileProps = this.get('data').getProperties(profilePropNames);
				this.get('onNext')(this.get('profile'), profileProps);
			},
			cancel: function cancel() {
				this.get('onCancel')();
			}
		},

		statusOptions: [{ text: 'Active', id: 'Active' }, { text: 'Inactive', id: 'Inactive' }, { text: 'Suspended', id: 'Suspended' }, { text: 'Test Mode', id: 'Test Mode' }],

		genderOptions: [{ text: 'Male', id: 'male' }, { text: 'Female', id: 'female' }, { text: 'Gender Fluid', id: 'gender-fluid' }, { text: 'Not Disclosed', id: 'not-disclosed' }],

		buildAiProgramJoinedOptions: [{ text: 'Yes', id: 'true' }, { text: 'No', id: 'false' }],

		languageOptions: [{ text: "English", id: "English" }, { text: "French", id: "French" }, { text: "Spanish", id: "Spanish" }],
		languageSelected: null
	});
});