define('aira-mgmt/models/address', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({

		address1: _emberData.default.attr('string'),
		address2: _emberData.default.attr('string'),
		address3: _emberData.default.attr('string'),
		addresstype: _emberData.default.attr('string'),
		city: _emberData.default.attr('string'),
		country: _emberData.default.attr('string'),
		county: _emberData.default.attr('string'),
		state: _emberData.default.attr('string'),
		validfrom: _emberData.default.attr('string'),
		zip: _emberData.default.attr('string'),

		userId: _emberData.default.belongsTo('user')
	});
});