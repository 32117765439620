define('aira-mgmt/helpers/minutes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.minutes = minutes;
  function minutes(input) {
    const minutes = ~~(input / 60); // ~~ removes the decimal
    const seconds = input % 60;
    return `${minutes} minutes ${seconds} seconds`;
  }

  exports.default = Ember.Helper.helper(minutes);
});