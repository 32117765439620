define('aira-mgmt/models/wifi', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		userId: _emberData.default.attr('number'),
		priority: _emberData.default.attr('number'),
		ssid: _emberData.default.attr('string'),
		securityType: _emberData.default.attr('string'),
		secretKey: _emberData.default.attr('string'),
		type: _emberData.default.attr('string')
	});
});