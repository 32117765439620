define('aira-mgmt/users/user/contacts/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),
    contacts: Ember.computed.alias('state.users.user.contacts'),

    notify: Ember.inject.service(),

    model(p) {
      return this.get('store').findRecord('contact', p.contact_id);
    },

    setupController(controller, model) {
      this._super(controller, model);
      // applies row highlight on transition from row click
      this.set('contacts.selectedContactId', model.id);
    },

    actions: {
      willTransition: function (transition) {
        if (this.get('state.isDirty') && !confirm('Are you sure? You have unsaved changes.')) {
          transition.abort();
        } else {
          this.set('contacts.selectedContactId', -1);
        }
      },

      save(contact, contactProps) {
        contact.setProperties(contactProps);
        return contact.save().then(() => {
          this.set('state.isDirty', false);
          this.get('notify').success('Contact was updated.');
          this.transitionTo('users.user.contacts');
        }, error => {
          this.send('error', error);
        });
      },

      cancel(contact) {
        contact.rollbackAttributes();
        this.transitionTo('users.user.contacts');
      },

      delete(contact) {
        contact.destroyRecord().then(() => {
          this.set('state.isDirty', false);
          this.get('notify').success('Contact was deleted.');
          this.transitionTo('users.user.contacts');
        });
      }
    }
  });
});