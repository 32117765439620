define("aira-mgmt/helpers/bannable", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.bannable = bannable;
  function bannable(deviceSerial) {
    return !deviceSerial[0];
  }

  exports.default = Ember.Helper.helper(bannable);
});