define('aira-mgmt/components/user-contact/component', ['exports', 'aira-mgmt/mixins/model-editor'], function (exports, _modelEditor) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	let contactPropNames = ['firstName', 'lastName', 'email', 'phone', 'relationship'];

	exports.default = Ember.Component.extend(_modelEditor.default, {

		state: Ember.inject.service(),

		title: null,

		contact: null,

		onSave: null,

		onCancel: null,

		onDelete: null,

		data: null,

		init() {
			this._super(...arguments);
			this._bindModelEditor('data', 'contact', contactPropNames);
		},

		actions: {
			selectRelationship(relationship) {
				this.set('data.relationship', relationship);
			},

			save() {
				const contactProps = this.get('data').getProperties(contactPropNames);
				this.get('onSave')(this.get('contact'), contactProps);
			},

			cancel() {
				this.set('state.isDirty', false);
				this.get('onCancel')(this.get('contact'));
			},

			delete() {
				if (confirm('Are you sure?')) {
					this.get('onDelete')(this.get('contact'));
				}
			}
		},

		relationshipOptions: [{ text: 'Spouse', id: 'Spouse' }, { text: 'Parent', id: 'Parent' }, { text: 'Child', id: 'Child' }, { text: 'Sibling', id: 'Sibling' }, { text: 'Partner', id: 'Partner' }, { text: 'Friend', id: 'Friend' }, { text: 'Other', id: 'Other' }]
	});
});