define('aira-mgmt/components/questions/question-multi-select/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    q: null,

    htmlId: null,

    options: null,

    selectedOptionValues: null,

    displayedFollowups: null,

    update: () => {},

    init() {
      this._super(...arguments);
      let q = this.get('q');

      this.set('htmlId', 'q-' + q.property.key);

      let options = [];
      q.property.options.forEach(opt => {
        if (opt.enabled) {
          options.push({
            id: opt.value,
            text: opt.value
          });
        }
      });

      this.set('options', options);

      let selectedOptionValues = [];
      q.values.forEach(uv => {
        selectedOptionValues.push(uv.value);
      });

      this.updateSelectionsAndDisplayedFollowups(selectedOptionValues);
    },

    updateSelectionsAndDisplayedFollowups(selectedOptionValues) {
      let q = this.get('q');

      this.set('selectedOptionValues', selectedOptionValues);

      let displayedFollowups = [];

      q.questionFollowUps.forEach(fu => {
        let match = selectedOptionValues.some(so => {
          return fu.values.includes(so);
        });
        if (match) {
          displayedFollowups.push(fu.questionFollowUp);
        }
      });

      // This will cause a re-render if changed.
      this.set('displayedFollowups', displayedFollowups);
    },

    actions: {
      select(selectedOptionValues /* array */) {
        this.updateSelectionsAndDisplayedFollowups(selectedOptionValues);
        this.update(this.get('q'), selectedOptionValues);
      },

      update(q, value) {
        this.update(q, value);
      }
    }
  });
});