define('aira-mgmt/users/user/properties/property/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        notify: Ember.inject.service(),

        platform: Ember.inject.service(),

        state: Ember.inject.service(),

        model(p) {
            return new Ember.RSVP.Promise((resolve, reject) => {
                Ember.RSVP.hash({
                    property: this.get('platform').doGet('user/property/' + p.property_key),
                    values: this.get('platform').doGet('user/' + this.modelFor('users.user').id + '/property/' + p.property_key + '/value')
                }).then(response => {
                    const property = response.property;
                    const values = response.values.payload;

                    // console.log('property', property);
                    // if(values) console.log('values', values);

                    let props = {
                        key: p.property_key,
                        property: property,
                        isMulti: false
                    };
                    if (values) {
                        props.property.values = values;
                        props.values = this.setProperties(property, values);
                        props.depth = this.getValueDepth(props.values) - 1;
                    }
                    if (props.property.type.startsWith('MULTI')) {
                        props.property.isMulti = true;
                    } else {
                        if (values && values.length) {
                            props.value = values[0].value;
                            props.children = values[0].children;
                        }
                    }

                    resolve(props);
                }, ({ errorCode, errorMessage }) => {
                    reject({ errorCode, errorMessage });
                });
            });
        },

        setupController(controller, model) {
            this._super(controller, model);
            controller.set('state', this.get('state'));
            controller.resetPendingUpdates();
        },

        setProperties(property, values) {
            let newValues = [];
            values.forEach(value => {
                value.property = property;
                value.type = property.type;
                if (value.property.type.startsWith('MULTI')) {
                    value.property['isMulti'] = true;
                } else {
                    value.property['isMulti'] = false;
                }
                newValues.push(value);
            });
            return newValues;
        },

        getValueDepth(values) {
            // console.log('getValueDepth~BEGIN', values);
            let max = 0;
            values.forEach(value => {
                const count = this.getValueDepthHelper(value);
                if (count > max) max = count;
            });
            return max;
        },

        getValueDepthHelper(value) {
            // console.log('getValueDepthHelper~BEGIN', value);
            let count = 1; // count self
            if (value.children) {
                Object.keys(value.children).forEach(child => {
                    let max = 0;
                    value.children[child].forEach(childValue => {
                        const childCount = this.getValueDepthHelper(childValue);
                        if (childCount > max) max = childCount;
                    });
                    count += max; // add max children count
                });
            }
            return count;
        },

        // SAVE HELPER FUNCTIONS
        getChildrenData(data, parentKey) {
            // console.log('getChildrenData~BEGIN', data, parentKey);
            let childrenData = {};

            Object.keys(data).forEach(key => {
                if (key.startsWith(parentKey) && key !== parentKey) {
                    childrenData[key] = data[key];
                }
            });

            // console.log('getChildrenData~END', childrenData);
            return childrenData;
        },

        getChildrenPayload(data, depth) {
            // console.log('getChildrenPayload~BEGIN', data, depth);

            let childrenPayload = {};

            Object.keys(data).forEach(key => {
                if (key.split('.').length === depth) {
                    let childrenValue = {};
                    childrenValue['value'] = data[key];
                    childrenPayload[key] = [];

                    let moreChildrenData = this.getChildrenData(data, key);

                    if (!Ember.isEmpty(moreChildrenData)) {
                        let moreChildrenPayload = this.getChildrenPayload(moreChildrenData, depth + 1);
                        childrenValue['children'] = moreChildrenPayload;
                    }

                    childrenPayload[key].push(childrenValue);
                }
            });

            // console.log('getChildrenPayload~END', childrenPayload);
            return childrenPayload;
        },

        makeDataHelper(data) {
            // console.log('makeDataHelper~BEGIN', data);
            let dataBefore = {};
            dataBefore[data.property.key] = data.value;

            let dataChildren = this.makeData(data.children);
            if (!Ember.isEmpty(dataChildren)) dataBefore = Object.assign(dataBefore, dataChildren);

            // console.log('makeDataHelper~END', dataBefore);
            return dataBefore;
        },

        makeData(data) {
            // console.log('makeData~BEGIN', data);

            let dataChildren = {};

            Object.keys(data).forEach(key => {
                data[key].forEach(value => {
                    dataChildren[key] = value.value;

                    let dataMoreChildren = this.makeData(value.children);
                    if (!Ember.isEmpty(dataMoreChildren)) dataChildren = Object.assign(dataChildren, dataMoreChildren);
                });
            });

            // console.log('makeData~END', dataChildren);
            return dataChildren;
        },

        actions: {
            // received from table row click
            selectValue(value) {
                this.transitionTo('users.user.properties.property.edit', { value: value, id: value.id });
            },

            save(data) {
                this.set('controller.inProgress', true);
                // console.log('save', data);
                // console.log('model', this.modelFor('users.user.properties.property').key);

                if (Ember.isEmpty(data)) return;

                let dataBefore = this.modelFor('users.user.properties.property').values;
                if (dataBefore && dataBefore.length) {
                    dataBefore = this.makeDataHelper(dataBefore[0]);
                }
                // console.log('dataBefore', dataBefore);

                const dataNew = dataBefore || {};

                Object.keys(data).forEach(key => {
                    dataNew[key] = data[key];
                });

                let payload = {};
                let parentKey = this.modelFor('users.user.properties.property').key;

                payload['value'] = dataNew[parentKey];
                let childrenData = this.getChildrenData(dataNew, parentKey);

                if (!Ember.isEmpty(childrenData)) {
                    let childrenPayload = this.getChildrenPayload(childrenData, 2);
                    payload['children'] = childrenPayload;
                }

                // console.log('payload', payload);

                this.get('platform').doPut('user/' + this.modelFor('users.user').id + '/property/' + parentKey + '/value', [payload]).then(() => {
                    this.get('notify').success('Edit Property Value Successfully Updated!');
                    this.get('controller').resetPendingUpdates();
                    this.transitionTo('users.user.properties');
                }, error => {
                    this.send('error', error);
                }).finally(() => {
                    this.set('controller.inProgress', false);
                });
            }
        }
    });
});