define('aira-mgmt/users/user/questions/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),

    model() {
      let id = this.modelFor('users.user').get('id');

      return Ember.RSVP.hash({
        user: this.get('store').findRecord('user', id),
        answers: this.get('store').query('answer', { userId: id })
      });
    },

    setupController(controller, model) {
      this._super(controller, model);
      // Provide state service to generated controller, avoids making our own.
      controller.set('state', this.get('state'));
    },

    actions: {
      // received from table row click
      selectAnswer(answer) {
        this.transitionTo('users.user.questions.edit', answer);
      }
    }
  });
});