define('aira-mgmt/models/service', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({

		agentLogin: _emberData.default.attr('string'),
		start: _emberData.default.attr('date'),
		end: _emberData.default.attr('date'),
		request: _emberData.default.attr('date'),
		type: _emberData.default.attr('string'),
		status: _emberData.default.attr('string'),
		durationMinutes: _emberData.default.attr('number'),
		requestSource: _emberData.default.attr('string'),
		testCall: _emberData.default.attr('string')
	});
});