define('aira-mgmt/serializers/wifi', ['exports', 'aira-mgmt/serializers/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({

		primaryKey: 'profileId',

		normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
			let profiles = payload.profiles;
			return this._super(store, primaryModelClass, profiles, id, requestType);
		},

		normalizeCreateRecordResponse(store, primaryModelClass, payload, id, requestType) {
			// Ridiculous hack because delete api doesn't provide the data in response
			return { data: { id: 0 } };
		},

		normalizeUpdateRecordResponse(store, primaryModelClass, payload, id, requestType) {
			// Ridiculous hack because update api doesn't provide the data in response
			const profile = store.peekRecord('wifi', id);
			return { data: profile.toJSON({ includeId: true }) };
		},

		normalizeDeleteRecordResponse(store, primaryModelClass, payload, id, requestType) {
			// Ridiculous hack because delete api doesn't provide the data in response
			const profile = store.peekRecord('wifi', id);
			return { data: profile.toJSON({ includeId: true }) };
		}
	});
});