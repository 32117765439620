define('aira-mgmt/users/user/referrals/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    userReferralCode: null,
    refereeEmail: null,
    actions: {}
  });
});