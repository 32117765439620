define('aira-mgmt/helpers/change-ISO-to-PST', ['exports', 'luxon'], function (exports, _luxon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.changeISOtoPST = changeISOtoPST;
  function changeISOtoPST([date]) {
    if (!date || date === '') return '';

    const parsedOnlyDate = _luxon.DateTime.fromFormat(date, 'yyyy-MM-dd', { zone: 'America/Los_Angeles' });
    if (parsedOnlyDate.isValid) {
      return date;
    }

    const dateTime = _luxon.DateTime.fromISO(date, { zone: 'UTC' });
    if (dateTime.isValid) {
      return dateTime.setZone('America/Los_Angeles').toFormat('yyyy-MM-dd HH:mm');
    } else {
      return date;
    }
  }

  exports.default = Ember.Helper.helper(changeISOtoPST);
});