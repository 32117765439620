define('aira-mgmt/users/user/properties/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    notify: Ember.inject.service()

  });
});