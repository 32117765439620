define('aira-mgmt/components/questions/question-boolean/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    q: null,

    htmlId: null,

    value: null,

    options: [{ id: 'true', text: 'Yes' }, { id: 'false', text: 'No' }],

    update: () => {},

    init() {
      this._super(...arguments);
      let q = this.get('q');

      this.set('htmlId', 'q-' + q.property.key);

      if (q.values.length > 0) {
        this.updateSelectionAndDisplayedFollowups(q.values[0].value);
      }
    },

    updateSelectionAndDisplayedFollowups(selectedOptionValue) {
      let q = this.get('q');

      this.set('value', String(selectedOptionValue));

      let displayedFollowups = [];

      q.questionFollowUps.forEach(fu => {
        let match = fu.values.includes(selectedOptionValue);
        if (match) {
          displayedFollowups.push(fu.questionFollowUp);
        }
      });

      // This will cause a re-render if changed.
      this.set('displayedFollowups', displayedFollowups);
    },

    actions: {
      select(selectedOptionValue /* array */) {
        this.updateSelectionAndDisplayedFollowups(selectedOptionValue);
        this.update(this.get('q'), selectedOptionValue);
      },

      update(q, value) {
        this.update(q, value);
      }
    }
  });
});