define('aira-mgmt/users/user/address/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    state: Ember.inject.service(),
    address: Ember.computed.alias('state.users.user.address'),

    notify: Ember.inject.service(),

    model(p) {
      return this.get('store').findRecord('address', p.address_id);
    },

    setupController(controller, model) {
      this._super(controller, model);
      // applies row highlight on transition from row click
      this.set('address.selectedAddressId', model.id);
    },

    actions: {
      willTransition: function (transition) {
        if (this.get('state.isDirty') && !confirm('Are you sure? You have unsaved changes.')) {
          transition.abort();
        } else {
          this.set('address.selectedAddressId', -1);
        }
      },

      save(address, addressProps) {
        address.setProperties(addressProps);
        return address.save().then(() => {
          this.set('state.isDirty', false);
          this.get('notify').success('Address was updated.');
          this.transitionTo('users.user.address');
        });
      },

      cancel(address) {
        address.rollbackAttributes();
        this.transitionTo('users.user.address');
      },

      delete(address) {
        address.destroyRecord().then(() => {
          console.log('delete');
          this.set('state.isDirty', false);
          this.get('notify').success('Address was deleted.');
          this.transitionTo('users.user.address');
        });
      }
    }
  });
});